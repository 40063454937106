import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const CoreValues = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='coreValues'>
            <motion.h2 variants = {item}>CORE VALUES </motion.h2>
            <motion.h3 variants = {item}>Integrity</motion.h3>
            <motion.p variants = {item}>Acting with honesty and honor without compromising the truth. Ownership taking care of the organization and its members as if they were one’s own. We shall work as keepers of each other. </motion.p>
            <motion.h3 variants = {item}>Accountability</motion.h3>
            <motion.p variants = {item}>Becoming aware of the situation, owning the problem, finding solutions, and making things happen. The organization depends on all of us thus we shall be counting on all of us for the betterment of our foundation</motion.p>
            <motion.h3 variants = {item}>Inclusiveness</motion.h3>
            <motion.p variants = {item}>Creating environments in which any individual or group can be welcomed, respected, supported, and valued to fully participate and bring their full, authentic selves to work.</motion.p>
            <motion.h3 variants = {item}>Honesty</motion.h3>
            <motion.p variants = {item}>Speaking fairness and truth. Honesty is not only about telling the truth but also about being real with yourself and others about who you are, what you want, and what you live up to your fullest potential. Honesty promotes openness and empowers us to live authentically.</motion.p>
            <motion.h3 variants = {item}>Kindness</motion.h3>
            <motion.p variants = {item}>Kindness is being friendly, generous, and considerate to others and yourself. Being kind often requires courage and strength, as it involves the willingness to celebrate and give attention to someone else. </motion.p>
    </motion.div>
  )
}

export default CoreValues