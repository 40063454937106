import React, {useState, useEffect, useRef} from 'react'
import "../css/Register.css"
import Processing from './Processing'
import useApiRequest from './useApiRequest'
import { domain } from './Domain'
import { motion, AnimatePresence } from 'framer-motion'

const RegTraining = () => {
    const { postRequest, response } = useApiRequest()
    const [loading, setLoading] = useState(false)
    const form = useRef()
    const initial = {
        name: "",
        username: "",
        year: "",
        contact: "",
        district: "",
        subcounty: "",
        parish: "",
        cell: "",
        email: "",
    }
    const [inputData, setInputData] = useState(initial)


    // Event handler for input change
    const handleInputChange = (event) => {
        const {name, value} = event.target
        setInputData({
            ...inputData,
            [name]: value
        });
    };

    const handleSubmit = async(event) => {
        setLoading(true)
        event.preventDefault()
        //console.log(inputData)
        const formData = new FormData()
        formData.append("regCategory","TrainingCenter")
        for (const key in inputData) {
            console.log(key, inputData[key])
            formData.append(key, inputData[key]);
        }
        
        const response = await postRequest(`${domain}/accounts/signup`, formData)
        console.log(response)

        setLoading(false)
        //resetiing the form after submission
        setInputData(initial)
        form.current.reset()
    }

    const handleClear = () => {
        setInputData(initial)
        form.current.reset()
    }

    return (
    <div className='register animate__animated animate__fadeIn'>
        <form onSubmit={handleSubmit} ref={form}>
            <div>
                <div className='divided'>
                    <div className='div'>
                        <label className="form-label">Name of company or institution</label>
                        <input type='text' placeholder='Enter your the name here' name='name' onChange={handleInputChange} value={inputData.name} />
                    </div>
                    <div className='div'>
                        <label className="form-label">Username</label>
                        <input type='text' placeholder='username' name='username' onChange={handleInputChange} value={inputData.username} />
                    </div>
                </div>
                <div className='divided'>
                    <div className='div'>
                        <label className="form-label">Years of operation</label>
                        <input type='number' placeholder='Enter years of operation' name='year' onChange={handleInputChange} value={inputData.year} />
                    </div>  
                    <div className='div d-block d-md-flex gap-4'>
                        <div className='w-100 w-md-50 flex-grow'>
                            <label className="form-label">phone number</label>
                            <input type='text' placeholder='Phone number' name='contact' onChange={handleInputChange} value={inputData.contact} />
                        </div>
                        <div className='w-100 w-md-50 flex-grow'>
                            <label className="form-label">Email</label>
                            <input type='text' placeholder='Enter your email address' name='email' onChange={handleInputChange} value={inputData.email} />
                        </div>
                    </div>
                </div>
                <div className='divided'>
                    <div className='div d-flex gap-4'>
                        <div className='w-50 flex-grow'>
                            <label className="form-label">District of origin</label>
                            <input type='text' placeholder='district of origin' name='district' onChange={handleInputChange} value={inputData.district} />
                        </div>
                        <div className='w-50 flex-grow'>
                            <label className="form-label">SubCounty</label>
                            <input type='text' placeholder='sub county' name='subcounty' onChange={handleInputChange} value={inputData.subcounty}/>
                        </div>
                    </div>
                    <div className='div d-flex gap-4'>
                        <div className='w-50 flex-grow'>
                            <label className="form-label">Parish</label>
                            <input type='text' placeholder='parish' name='parish' onChange={handleInputChange} value={inputData.parish} />
                        </div>
                        <div className='w-50 flex-grow'>
                            <label className="form-label">Cell</label>
                            <input type='text' placeholder='cell' name='cell' onChange={handleInputChange} value={inputData.cell} />
                        </div>
                    </div>
                </div>
                <button class="btn btn-success mt-2 mb-2">Submit Registration Data</button>
                <button type='button' onClick={handleClear} className='btn btn-danger ms-2'>Clear Form</button>
            </div>
        </form>
        <AnimatePresence>
            {loading && (
                <div className="processing-container">
                    <motion.div 
                        key={loading} 
                        initial={{ opacity: 0, scale: 0 }} 
                        animate={{ opacity: 1, scale: 1 }} 
                        exit={{ scale: 0, opacity: 0 }} 
                        className='processing'
                    >
                        <Processing />
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    </div>
    )
}

export default RegTraining