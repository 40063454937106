import './App.css';
import 'animate.css';
import { Route, Routes} from "react-router-dom"
import Entry from './components/Entry';
import Login from './components/Login';
import Register from './components/Register';
import Contact from './components/Contact';
import Dashboard from './components/Dashboard';
import Donate from './components/Donate';
import Activation from './components/Activation';
import ForgotPassword from './components/ForgotPassword';
import PasswordResetForm from './components/PasswordResetForms';
import { ActivePageProvider } from './components/ActivePageContext';
import PrivateRoutes from './components/ProtectedRoutes';

function App() {
  return (
    <div className="App">
      <ActivePageProvider>
        <Routes>
          <Route path='/' element={<Entry />} />
          <Route path='/login' element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/accounts/password-reset/:slug' element={<PasswordResetForm />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/donate' element={<Donate />} />
          <Route path='/register' element={<Register />} />
          <Route element={<PrivateRoutes />}>
            <Route path='/dashboard' element={<Dashboard />} />
          </Route>
          <Route path='/accounts/activation' element={<Activation />} />
        </Routes>
      </ActivePageProvider>
    </div>
  );
}

export default App;
