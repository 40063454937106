import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const Pillar3 = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='pillarDetails'>
            <motion.h2 variants = {item}>THE DIGITAL SUBSISTENCE FARMERS MARKETS</motion.h2>
            <motion.p variants = {item}>A thriving Uganda where graduates are skilled, rural communities flourish, and sustainable farming practices drive prosperity. UGAFo envisions a nation where fair opportunities prevail, farmers' rights are protected, and innovation hubs foster entrepreneurial success. We aspire to be the catalyst for positive change, shaping a future where every district is a center for agricultural excellence and value addition, contributing to a resilient and inclusive Ugandan society.</motion.p>
            <motion.h3 variants = {item}>Community access to inputs</motion.h3>
            <motion.p variants = {item}>This focuses on protecting farmers from exorbitant prices charged by the retailers and wholesalers of agricultural inputs. It also focuses protecting farmers from being supplied with counterfeit inputs which has always been the case in Uganda. UGAFo outlets for all agricultural inputs will be opened in all districts in Uganda and different companies shall be invited to partner with UGAFo and keep on supplying these inputs directly to the farmers without involvement of any middle men to keep the prices affordable at factory price with manageable charges of delivery to the farmers only since UGAFo is not a profit-oriented foundation. All the inputs ranging from quality seeds, herbicides, fertilizers and all agricultural equipment such as hoes, sprayers, etc.</motion.p>
            <motion.h3 variants = {item}>Post-harvest handling practices</motion.h3>
            <motion.p variants = {item}>Activities done under the post-harvest handling will include;</motion.p>
            <motion.p variants = {item}>Quality control for international standards</motion.p>
            <motion.h3 variants = {item}>Storage</motion.h3>
            <motion.p variants = {item}>UGAFo will focus on establishing storage facilities at district level and utilizing the available storage facilities at SMEs in Uganda for bulk storage at regional level. Silos will be constructed in cereal producing districts in line with the quantity the given districts will be producing. Silos will help to maintain the international storage standards and meet export standards for Uganda’s agricultural produce. </motion.p>
            <motion.p variants = {item}>Storage sites at parish level. These will be established using the model farmers system which is already existing established by the ministry of agriculture fisheries and animal industry, storage facilities will be constructed at their homes and will be operated communally for temporally storage of commodities before they are transferred to district storage facilities. 	</motion.p>
            <motion.p variants = {item}>Collection centers at lc1 level where the electric weighing scale will be stationed. </motion.p>
            <motion.h3 variants = {item}>Packaging</motion.h3>
            <motion.p variants = {item}>This will be done at district level and the batch number will track the commodities by their sub county of origin. All products will be weighed and pre priced before hitting the market. </motion.p>
            <motion.p variants = {item}>Branding</motion.p>
            <motion.p variants = {item}>All products produced with in a given district will be branded by the district of origin. For example, Irish from kabale will be branded by variety, kabale Irish, batch number eg. District code, 20, Sub county code, 002, and commodity number 123 combined as 20.002.123 quantity and price. All the identified products will follow the same trend except sugar type sugar canes which will follow the available measuring standards provided by sugar companies. </motion.p>
            <motion.h3 variants = {item}>Value addition. </motion.h3>
            <motion.p variants = {item}>This will be done by direct contact with already existing and well-established companies like Mbale millers, etc and must have UNBS stamp and active ura tin number. With time UGAFo farmers will start selling their products to markets in final product, by having all the processing stages done from the district level.</motion.p>
            <motion.p variants = {item}>The selected crops for the dfms</motion.p>
            <ul>
                <li>Beans</li>
                <li>Groundnuts (G.nuts)</li>
                <li>Maize</li>
                <li>Matooke</li>
                <li>Irish Potatoes</li>
                <li>Cassava</li>
                <li>Sugar Canes (*Sugar type)</li>
                <li>And poultry for urban centers and semi-arid areas. </li>
            </ul>
            <motion.h3 variants = {item}>The UGAFO app</motion.h3>
            <motion.p variants = {item}>Online selling through the UGAFo app and direct contact with already established online marketers eg, wasoko, keshwala, egate, kyosk, and kikuubo online in Kampala areas for a wider coverage and deep penetration to retailers will be used. Also, direct contact with already existing and well-established milling companies like Mbale millers, and any other companies that are certified by UNBS and have active URA tin numbers shall be directly contacted for supply of maize. The government of Uganda will be also contacted for supply of agricultural products to its parastatals that may need supply of agricultural produce. Only cash sales will be accepted by UGAFo no matter what. It’s either cash sales or no sales. If farmers are buying all inputs in cash, there is no reason why anyone should be buying on credit even if it’s a government agency. </motion.p>
            <motion.h3 variants = {item}>Transporting items to the market.</motion.h3>
            <motion.p variants = {item}>UGAFo will contact already established transport companies with valid URA tin numbers as well as well-trained drivers that meet all the traffic rules and regulations operating in Uganda to take over the contracts of distributing agricultural products from one area to another. With time, UGAFo will buy some refrigerated trucks to help in transporting matooke, and box body trucks for transporting grains to maintain the quality of the agricultural produce in Uganda. UGAFo will not tolerate any form of bribery to any traffic officers and will investigate any situation where traffic officers will be involved along the way and try to find out the cause and how to avoid it next trip. UGAFo will follow all stipulated traffic guidelines to avoid extra charges and fines that have always accompanied the delivery of agricultural products in Uganda. </motion.p>
            <motion.p variants = {item}>All road tolls requiring district taxes will be observed and the money paid to that given district will be recorded and aired out on the radio after the end of every year and the district officials will be tasked to give accountability of the collected amount of money. </motion.p>
            <motion.h3 variants = {item}>The UGAFo Pricing index</motion.h3>
            <motion.p variants = {item}>The UGAFo price index shall be adopted to avoid losses that have always made farming in Uganda a no-go zone for most Ugandans. </motion.p>
            <motion.p variants = {item}>The UGAFo price index states,</motion.p>
            <motion.p variants = {item}>Tp1 + Tp2 + Fu.p + Sp + Wp + Fp + Ha.p + Pp + St.p /output.acre= FGP</motion.p>
            <motion.p variants = {item}>Where by;</motion.p>
            <motion.p variants = {item}>Tp1 = Tilling price 1st tillage</motion.p>
            <motion.p variants = {item}>Tp2 = Tilling price 2nd tillage</motion.p>
            <motion.p variants = {item}>Fu.p = Furrowing price</motion.p>
            <motion.p variants = {item}>Sp = Seeds price</motion.p>
            <motion.p variants = {item}>Wp = Weeding price</motion.p>
            <motion.p variants = {item}>Fp = Fertilizer price</motion.p>
            <motion.p variants = {item}>Ha.p = Harvesting price</motion.p>
            <motion.p variants = {item}>Pp = Packaging price</motion.p>
            <motion.p variants = {item}>St.p = Storage price</motion.p>
            <motion.p variants = {item}>F.G.P = Farm gate price </motion.p>
            <motion.h3 variants = {item}>Government’s protection and URA </motion.h3>
            <motion.p variants = {item}>UGAFo will engage with different government parastatals to make sure that all the farming activities are done in line with policies operating with in Uganda without breaking the law. UGAFo will also engage the government for protection from imports that may enter the country and flood the markets and lead to staggering agricultural prices in Uganda. Balance of trade must be observed. Countries should not sell their excess produce to Uganda and destabilize production in Uganda. For example, if Tanzania wants to sell rice to Uganda, there must be some agreed upon quantity that can be allowed from Tanzania or only in times of scarcity but not filling the whole market with Tanzanian rice leaving Ugandans outcompeted before they even venture into farming. The market for agricultural products must be protected if price fluctuation is to ever be solved in Uganda and Africa at large. There should be similar prices of agricultural products through the year by controlling demand and supply with some scientific measures. </motion.p>
    </motion.div>
  )
}

export default Pillar3