import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const Membership = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='membership'>
            <motion.h2 variants = {item}>MEMBERSHIP TO UGAFo</motion.h2>
            <motion.p variants = {item}>The applicant must be a Ugandan above 18 years of age of sober mind and willing to work for development. </motion.p>
            <motion.ul variants = {item}>
                <li>Create an account and fill in the required details as an apprentice, UGAFo Ambassador, buyer, training center, or farmer. </li>
                <li>Buy a share in the UGAFO – Credit scheme of 20,000 shillings and receive a UGAFo savings and credit account at once. Nb, this is the account through which all financial transactions in UGAFo will be taking place e.g. paying graduates doing their apprenticeship, farmers buying inputs and equipment from UGAFo, farmers selling their produce etc. all the services will be available at all branches of UGAFo that will open all over the country. </li>
                <li>Upload your updated CV, and a pass port size photo. (for graduates). For UGAFo ambassadors, replace cv with admission form. For buyers and farmers, only upload a recent colored passport size photo. </li>
                <li>Submit and wait for the confirmation message with your UGAFo savings and credits account with the UGAFo credit scheme.</li>
                <li>Click here to create an account with the UGAFo credit scheme.  </li>
            </motion.ul>
    </motion.div>
  )
}

export default Membership