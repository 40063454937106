import React from 'react'
import "../css/Header.css"
import { Link, useNavigate } from 'react-router-dom'
import { useLoginContext } from './LoginContext';
import { domain } from './Domain';
import useApiRequest from './useApiRequest';
import { useActivePage } from './ActivePageContext';


const Top = () =>{
    return(
        <div className='top d-flex align-items-center justify-content-between text-white'>
            <div>
                <p>United Graduates Apprenticeship Foundation (UGAFo)</p>
            </div>
            <div className='d-flex gap-4'>
                <i class="fa-brands fa-twitter"></i>
                <i class="fa-brands fa-facebook-f"></i>
            </div>
        </div>
    )
}

const Navigator = () => {
    const { activePage, setActivePage } = useActivePage();
    const navigate = useNavigate()
    const { postRequest, response } = useApiRequest()
    const { isLoggedIn, setIsLoggedIn, userInfo, setUserInfo } = useLoginContext();
    const initial = {
        id: null, 
        token: null, 
        name: null, 
        date: null,
        image: null,
        role: null,
        is_super:null,
        user_type:null
    }

    const handleLogOut = async() => {
        const response = await postRequest(`${domain}/accounts/logout`, {})
        if(response['message'] === "logout successfull"){
            setUserInfo(initial)
            localStorage.setItem('isLoggedIn', false)
            setIsLoggedIn(!isLoggedIn)
            navigate("/")
        }
    }

    return(
        <div className='navigator d-flex align-items-center justify-content-between flex-wrap'>
            <img src='/images/logo.png' alt='logo' />
            <nav className='d-flex gap-3'>
                {isLoggedIn === true && <Link to={"/dashboard"} onClick={()=>setActivePage("dashboard")} className={activePage === 'dashboard' ? 'active' : ''}>Dashboard</Link>}
                <Link to={"/"} onClick={()=>setActivePage("home")} className={activePage === 'home' ? 'active' : ''}>Home</Link>
                <Link to={"/contact-us"} onClick={()=>setActivePage("contact")} className={activePage === 'contact' ? 'active' : ''}>Contact Us</Link>
                <Link to={"/donate"} onClick={()=>setActivePage("donate")} className={activePage === 'donate' ? 'active' : ''}>Donate</Link>
            </nav>
            {isLoggedIn === false ?
                <div>
                    <Link to={"/login"}>Login</Link>
                    <Link to={"/register"}>Create Account</Link>
                </div>
                :
                <div className='d-flex align-items-center gap-2'>
                    <img className='img-thumbnail' src={domain+userInfo.image} />
                    <p>Welcome {userInfo.name}</p>
                    <Link className="bg-danger" onClick={handleLogOut}>Logout</Link>
                </div>
            }
        </div>
    )
}

const Header = () => {
  return (
    <div className='header'>
        <Top />
        <Navigator />
    </div>
  )
}

export default Header