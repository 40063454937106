import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const Services = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='services'>
            <motion.h2 variants = {item}>SERVICES PROVIDED BY UGAFo</motion.h2>
            <motion.ul variants = {item}>
                <li>Apprenticeship placements for Ugandan graduates.</li>
                <li>Operation of farmers’ digital markets (FDMs).</li>
                <li>Credit extension through the UGAFO credit scheme.</li>
                <li>Affordable legal services for all members of the foundation relating to agriculture, marketing, and land. This will be facilitated by our law graduates under the foundation. </li>
                <li>Provision of communal storage facilities for farmers in our foundation as well as post-harvest handling services.</li>
                <li>Free agricultural extension services to all farmers in our foundation done by our graduates of agriculture operating the agricultural line of credit. Extension workers will also handle inputs necessities of farmers and have them delivered to the farmers at some affordable price. </li>
                <li>Bridging the gap between the farmers and the global market as well as price stability by making farmers price makers not price takers. This will protect farmers from exploitation that has been caused by middle men who buy at a very cheap price from farmers and sell at exorbitant prices that the farmers cannot afford incase their granaries run dry as well as accessing seeds for the next season.</li>
                <li>Starting and operating UGAFo cohorts in all districts in Uganda.</li>
            </motion.ul>
    </motion.div>
  )
}

export default Services