import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const Pillar1 = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='pillarDetails'>
            <motion.h2 variants = {item}>APPRENTICESHIP FOR GRADUATES</motion.h2>
            <motion.p variants = {item}>This is the kind of training where a graduate is working as an apprentice. The graduate earns a stipend to help him or her cater for his or her accommodation and simple personal needs. </motion.p>
            <motion.p variants = {item}>Under this scheme, UGAFo will recruit all graduates in Uganda who have not yet found formal employment positions in their areas of profession. The graduates will be attached to different training centers in Uganda and they will be able to work full time in their areas of profession. The main objective in this idea is to develop the skills of the graduates and prepare them for formal employment. </motion.p>
            <motion.p variants = {item}>The graduates who will not be able to get placements in the training centers, will all be deployed in the UGAFo cohorts and serve in their areas of profession. </motion.p>
            <motion.p variants = {item}>All graduates will upload their CVs, and a recent colored passport photo. This will be available for all people who login as employers and they will be able highlight the candidates that meet their requirements. UGAFo officers will then contact the highlighted candidate and have his or her apprenticeship contract signed between, UGAFo, training center operators, and the graduate. The graduates will have to present details of one guarantor with details of national id, and fill the guarantors form signed by the lc1 chairperson upon deployment. </motion.p>
            <motion.p variants = {item}>Once the graduate has been deployed, his or her details will be inactivated so that other employers may be able to identify who has not been deployed and have him or her taken up.  </motion.p>
            <motion.p variants = {item}>All apprentices at the level of degree will be entitled to a stipend worth 300,000 shillings per month, and those with diploma 200,000, and 150,000 for certificate holders to help them put up. In addition to the above amount, the training centers they are attached to will deposit 50,000 shillings per month on each apprentice’s account in the UGAFo credit scheme to act as social security fund for the apprentices. The apprentices will only access this money on their savings scheme account after completing a period of one year of uninterrupted full time working. In case of indiscipline by the graduate during the course of one year, the company that has been training the given apprentice will reclaim all its money saved with the UGAFo scheme. In this case only cases related to the rules and regulations of the given company will be considered and shall be availed to the apprentices before signing the contract. Apprentices who will get jobs with in the period of one year, their savings will be availed to them at once as they handover. Those who will be retained as staff after one year will be paid their full amount per month without saving scheme of 50,000 per month or save by choice. Those who choose to go after one year will receive their certificate of on-job training and their savings at once (600,000 shillings in one year).</motion.p>
            <motion.p variants = {item}>While undergoing the training, all apprentices will be receiving all job alerts every day through our UGAFo you tube channel and encouraged to keep on applying to different employment opportunities as many as possible. For every application made, the graduates will receive the recommendation letter from the company he or she is attached to as well as the UGAFo recommendation from the president of UGAFo in Uganda. </motion.p>
            <motion.p variants = {item}>The graduate will use the name of the immediate supervisor, the branch manager and the UGAFo managers and administrators as the referees to help them stand higher chances of being shortlisted. </motion.p>
            <motion.p variants = {item}>Incase their applications are not considered; our legal teams will follow up the application to determine why these graduates were not shortlisted so that UGAFo can rectify such challenges the next time these candidates are applying again.</motion.p>
            <motion.p variants = {item}>In cases where the graduates will do interview and fail to qualify for the given job, our legal teams will still follow it up to determine why the candidate failed and the candidate will be informed about the unfortunate news. In situations where it is in public service, the legal team will require to look at the performance of the candidates that were considered successful. If it happens that the successful candidates did not have the required marks and qualification, then our legal team will go ahead to sue the district service commission into the courts of laws to answer why they were corrupt in their recruitment process. And copies of the lawsuit will be shared with the ministry of public service. </motion.p>
            <motion.p variants = {item}>All apprentices and UGAFo ambassadors will be allowed to access stipend-based loans not exceeding 500,000 shillings to help them acquire some assets in life. </motion.p>
            <motion.p variants = {item}>All apprentices will be asked to apply for the USA dv lottery that happens every year to increase chances of graduates going abroad with clear documents and able to work in their fields of operation.</motion.p>
            <motion.h3 variants = {item}>UGAFo SHORT COURSES</motion.h3>
            <motion.p variants = {item}>UGAFo will conduct short courses to all members in areas shown below, and successful candidates will be awarded with a certificate. All these causes will be online with online exams. This is aimed at equipping graduates with computer skills which are necessary in today’s generation as the world is getting sophisticated with every aspect of life computerized. The online certificates offered will include,</motion.p>
            <motion.ul variants = {item}>
                <li>Computer literacy.</li>
                <li>Cyber security.</li>
                <li>Website design.</li>
                <li>Software maintenance and computer repair.</li>
                <li>Climate smart agriculture.</li>
            </motion.ul>
            <motion.h3 variants = {item}>ON JOB TRAINNING CERTIFICATES.</motion.h3>
            <motion.p variants = {item}>All apprentices who will successfully complete their training without being interrupted by any circumstances will be awarded with a certificate of on job training and UGAFo recommendation letter accompanied by the company recommendation letter to help the graduates in their future life of looking for better jobs. </motion.p>
            <motion.p variants = {item}>After completing one year of training, the company will decide whether to formally employ the given graduate or whether to keep him or her as an apprentice indefinitely. It will be the choice of the apprentice to decide whether to continue with the company he or she has been attached to or leave. New contracts will be signed for those who will be formally employed and those who will be maintained as apprentices they will maintain the old contract. For those who will be released, will get their recommendations as well as the on-job training certificate and chance of using the company’s administrators as referees on their future applications. </motion.p>
            <motion.p variants = {item}>If the graduate decides to leave the training center that he or she has been attached to, he or she will be having only one option of joining the UGAFo cohorts and join the indefinite apprenticeship where he or she is free to leave at any point by choice. </motion.p>
            <motion.p variants = {item}>Graduates who will not get placements in the given training centers and be deployed in the UGAFo cohorts will receive an on-job training certificate, a recommendation letter from the UGAFo cohort manger, and the general UGAFo recommendation letter.</motion.p>
            <motion.p variants = {item}>Apprentices who will drop out of the training or get jobs somewhere else will only get recommendation letters without the on-job training certificate. </motion.p>
            <motion.p variants = {item}>UGAFo will hold a graduation ceremony every year in December as companies close off their year and have the graduates awarded on job training certificates. </motion.p>
            <motion.h3 variants = {item}>THE UGAFO AMBASSADORS. </motion.h3>
            <motion.p variants = {item}>Students of 1st, 2nd year and 3rd or 4th year will be recruited into UGAFo as UGAFo ambassadors. </motion.p>
            <motion.p variants = {item}>They will be allowed to work in the UGAFo cohorts temporally during holidays and will be paid on daily basis a stipend not below 5000 shillings per day worked. </motion.p>
            <motion.p variants = {item}>The UGAFo ambassadors will follow the membership procedure to become members of UGAFo, and they will directly become UGAFo members after finishing their courses and receiving their documents to upload and join the rest in competing for the available apprenticeship placements available on the UGAFo website. </motion.p>
            <motion.p variants = {item}>The UGAFo ambassadors will also be allowed to do their internship from the UGAFo cohorts and have their university supervisors mark their work with full training from the UGAFo cohorts as they will be covering most of the areas of profession. </motion.p>
            <motion.p variants = {item}>There will be no any certificates issued to the ambassadors. They will only work to develop their skills. </motion.p>
            <motion.p variants = {item}>The UGAFo ambassadors will receive UGAFo account with the UGAFo credit scheme where they can deposit and withdraw their money and incase of failure to complete tuition on time, UGAFo will step in and have the graduate do his exams in with peace of mind. This will be covered under the UGAFo credit scheme (stipend-based loans)</motion.p>
    </motion.div>
  )
}

export default Pillar1