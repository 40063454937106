import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const Pillar2 = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='pillarDetails'>
            <motion.h2 variants = {item}>THE UGAFO COHORTS</motion.h2>
            <motion.h3 variants = {item}>THE EDUCATION COHORT. </motion.h3>
            <motion.p variants = {item}> Education cohort, education for all theorem targets refuges and hard to reach areas.</motion.p>
            <motion.h3 variants = {item}>THE HEALTH COHORT. </motion.h3>
            <motion.p variants = {item}>Medical cohort, all lives matter theorem target refuges and hard to reach areas. </motion.p>
            <motion.h3 variants = {item}>UGAFo will start clinics at all levels of operation to help peasants access quality health care at affordable rates. </motion.h3>
            <motion.p variants = {item}>THE LEGAL COHORT</motion.p>
            <motion.h3 variants = {item}>UGAFo will work with the law students to Advocate for a just Uganda. </motion.h3>
            <motion.p variants = {item}>These lawyers will help poor peasants who are always denied justice because of the cost of paying lawyers. In this case, UGAFo with stand with the afflicted and have justice served. </motion.p>
            <motion.h3 variants = {item}>THE MDD COHORT </motion.h3>
            <motion.p variants = {item}>UGAFo will build the biggest studio ever in Africa for audio and video production operated by UGAFo Band. This will help develop talents of Ugandans whose talents are dying out because of having no opportunity to shine like others. </motion.p>
            <motion.h3 variants = {item}>THE AGRICULTURAL COHORT</motion.h3>
            <motion.p variants = {item}>This will include two subsectors as described below. With each led by the station manager reporting to the district coordinator.</motion.p>
            <motion.h3 variants = {item}>THE GRADUATES DEMONSTRATION FARMS</motion.h3>
            <motion.p variants = {item}>Graduates’ demonstration farms target people with land to develop, grow Hass avocados, cocoa and coffee for export. UGAFo will buy land and establish foundation demonstration farm estates managed by graduates in every district depending on the climatic conditions of the given district as well as the favorable cash crops shriving in that district. Districts whose conditions are not favorable for agriculture as well as urban areas where land is scarce, UGAFo will establish poultry units and have graduates employed in poultry. Focus will be on exporting all products produced by these demonstration farms and also act as training centers for hands on experience in agriculture for all graduates of Uganda. The products produced by the UGAFo farmers linked to these demonstration farms will be sold together with the products produced from these demonstration farms as long as they meet the required standards for export.</motion.p>
            <motion.h3 variants = {item}>THE AGRICULTURAL COMMODITY EXCHANGE SCHEME </motion.h3>
            <motion.p variants = {item}>Through this scheme, UGAFo will be transporting agricultural produce from region to region to make food accessible to UGAFo members at affordable prices. This food will be stored at district level and supplied to the local distributors for easy access to the peasants. This will increase market share of agricultural produce at national level as some areas have been starving with shortage of food when food is getting spoiled in other regions of the country yet we are all Ugandans just separated by distance and different climatic conditions. The districts supplying the commodities will balance them off in their books of records and the receiving districts will buy the products and balance the supply chain. UGAFo will cover the amount of money required to pay off for the commodities. In cases of insufficient finance on the UGAFo account to pay off the farmers engaging in the exchange program, UGAFo will call on the ministry of disaster and preparedness to avail some funds to make food available for the region in scarcity. According to UGAFo, famine is a disaster that should call for state intervention. </motion.p>
            <motion.p variants={item}>This will help to fight scarcity of food in Uganda by maintaining uniformity of supply of agricultural produce available at UGAFo stores in Uganda. For example, if the beans are ready in kisoro but not yet ready in kapchorwa, UGAFo will transport beans from kisoro to kapchorwa to fill the available gaps in supply of beans in kapchorwa. This is so important because Uganda has totally different climatic conditions which affects growth of crops in Uganda. Research has shown that cold regions like kisoro have longer gestation periods than warm regions like Bushenyi and Rukungiri. All farmers who subscribe to UGAFo will access the commodities agreed upon prices and will only add storage facility and transportation fees not exceeding 300shillings per killo.</motion.p>
            <motion.h3 variants = {item}>AGRICULTURAL IMAGE AND ATTITUDE CAMPAIGN</motion.h3>
            <motion.p variants = {item}>In Uganda research has shown that over 80percent of Ugandans live in rural areas and directly engaged in subsistence farming. </motion.p>
            <motion.p variants = {item}>In the year 2022/2023, agriculture accounted for about 24% of Uganda’s GDP, and 35% of export earnings. The Uganda bureau of statistics estimates that about 68% of Uganda’s population is employed in agriculture. By 2022, GDP from agriculture in Uganda had increased to 10,865.65 ugx billion.</motion.p>
            <motion.p variants = {item}>With the current population of Uganda estimated to be 49,061,046, where 68% directly translates to 33,361,511.28 Ugandans directly earning their living from agriculture and in Ugandan context meaning subsistence farming, using agricultural practices as a measure of disciplining wrong doers in schools, prisons, and some well-off families remains a grave mistake in our generation and must stop. </motion.p>
            <motion.p variants = {item}>In this context, farming should not be seen as a punishment in schools, prisons and some well-off families or as an activity done by those who have failed in life and the low privileged. This campaign will put an end to using of inmates in Ugandan prisons in fields of agriculture as well as putting an end to paid child labor in agriculture especially those below 12years. Agriculture is a passion pursued by those interested but not a condition of failure in life. </motion.p>
            <motion.p variants = {item}>Agricultural sector remains the backbone of Uganda’s economy as it’s the main source of livelihood and employment. Then why use the same sector as a punishment in the same country? Do you want to have 33,361,511.28 Ugandans living under life that is equal to punishment? NOO. UGAFo will revert it all.</motion.p>
            <motion.p variants = {item}>UGAFo will create a special team of graduates to head a national wide campaign to educate the general public about the great contributions of agriculture toward the development of this country and the dangers of using agriculture as punishment in Uganda. </motion.p>
            <motion.p variants = {item}>UGAFo will engage all stake holders especially the district police commanders, RDCs, agriculture officers and parliament to decamping this problem.</motion.p>
            <motion.p variants = {item}>UGAFo will also engage parliament of Uganda to enact a law that prohibits all Ugandans from using prisoners, and children below 12 in paid agricultural labor. This will also target schools which use agriculture as a mode of punishing wrong doers at school. A toll-free number will be availed to all students in Uganda to report cases where agricultural practices are being used as punishment of any kind. All punishments that involve using of garden tools such as hand hoes, pangas, hooked hoes, hoe, saw, sprayer, trowel, watering can, pruning shears, etc. shall be condemned.</motion.p>
    </motion.div>
  )
}

export default Pillar2