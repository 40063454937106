import React from 'react'
import{ motion } from "framer-motion"
import "../css/Details.css"

export const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        //delayChildren: 0.2,
        //staggerDirection: 1,
        //duration: 2
      }
    }
}
  
export const item = {
    hidden: { opacity: 0, x: -100 },
    //show: { opacity: 1, x: 0, transition: {type: "spring", bounce: 0.25,  stiffness: 200}}
    show: { opacity: 1, x: 0}
}

const Formation = () => {

    return (
        <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className='formation'
            >
                <motion.h2 variants = {item}>Formation of UGAFo</motion.h2>
                <motion.p variants = {item}>From the ancient world to modern day life, it has been said that the youthful age works for the old age of every man kind. What about the current situation where the youthful life is spent in school and there is no job after graduation? All adverts running on air and the newspapers ask for experience of at least two years with need for recommendation and referees to get shortlisted. What about the fresh graduates? How will they ever attain the given experience? Much is needed to bridge this gap thus need for graduates’ apprenticeship foundation. </motion.p>
                <motion.p variants = {item}>With 43 universities in Uganda where 13 are public universities and 12 private chartered universities, the number of Graduates moves up arithmetically every year in Uganda. It is projected that with technological advancement such as artificial intelligence and robotic innovations, the jobs will continue to be scarce and reach a point where computerized systems can replace manual labor and lead to a crush in the job market. </motion.p>
                <motion.p variants = {item}>According to the president of UGAFo Mr. Avitus Great Ariho, with current population growth rate of 3.32% in Uganda, the population is likely to double by 2053.Which means that, by the time our children graduate, the population in Uganda will be already out of our hands making life barely possible for our old age without pension or NSSF to cater for retirement. </motion.p>
                <motion.p variants = {item}>Considering the rate of land fragmentation in Uganda, and the land tenure systems in Uganda where land is largely in hands of the rich class which is employed in Government offices without time to practice agriculture the country is at high risk of food insecurity by 2035 and total famine by 2053 which is contrary to the goals of NDPiii and the MAPUTO declaration of 2003 as well as the vision 2040 of the NRM government. The poor class of citizens is the one engaged in agriculture thus leading to persistence of subsistence farming in Uganda without capacity for commercialized farming in Uganda due to challenges of cost of capital. This has led to a big shift in income inequalities where by people who are employed continue to live a better life and able to send their children to good schools. At the end, those who go to good schools pay high fees dues, perform better, do more competitive courses at the university, get government scholarships and study on merit, get exposed by their working parents to the working environment, and end up on good paying jobs with good NSSF schemes or pension if in civil service. </motion.p>
                <motion.p variants = {item}>The children from subsistence families on the other hand study from local schools, struggle hard to perform better, some doing child labor to get the school fees, perform somehow better, go to the nearby high schools, and score to somehow good level after too much effort from scratch, go to university when the parents are sacrificing all their life for the future of their children, some even sell land when the sky gets gray especially when the son or daughter is nearly graduating. After completing the course graduates go back to their home villages to engage in subsistence farming with the same parents who sold their small piece of land to send their child to school. The graduates cannot access job adverts, no internet access, no new papers and end up giving up or being a public nuisance with frustration. </motion.p>
                <motion.p variants = {item}>This kind of frustration has led to alcoholism, drug addiction and being targeted by politicians to achieve their dreams using the youths as stepping stones to their success. </motion.p>
                <motion.p variants = {item}>This is the main reason why teaming up and unionizing for one voice as the united graduates apprenticeship foundation (UGAFo) is the only solution to stop these problems from sinking our great country. </motion.p>
                <motion.p variants = {item}>The president of the republic of Uganda H.E General Yoweli Kaguta Museveni has always encouraged Ugandans to form groups to help themselves to develop. Presidential initiatives have been formed aimed at skilling the uneducated youths leaving out graduates.</motion.p>
                <motion.p variants = {item}>Boda Boda riders have united and formed SACCOs, teachers have UNATU, doctors have their own unifying platform, and Engineers have their umbrella of Uganda Institute of Professional Engineers (UIPE). The unemployed graduates and the peasants remain the only isolated groups thus need for one foundation that stands for all.</motion.p>
                <motion.p variants = {item}>Most of the graduates have given up on their professions and gone to the Arab world to work as casual laborers something which kills their professional skills. After coming back, they start businesses without any exposure in the Ugandan context and collapse in few months.  Most of the graduates who go abroad to search for greener pastures largely go because of failing to find jobs in their areas of profession, and for the sake of their future they opt to going abroad to do any work available even if it means doing casual work. By the time they complete their contracts averagely two years, they come back to Uganda when it’s hard for them to be absorbed in the jobs market here in Uganda because of having no experience, at the end of it all, they end up giving up on their degrees they worked so hard to attain. </motion.p>
                <motion.p variants = {item}>The president of the republic of Uganda H.E Gen. Yoweli Kaguta Museveni together with all his cabinet ministers as well as different heads of government parastatals in Uganda have always encouraged the youths especially the fresh graduates to be self-employed which seems to be the only way to go in the present generation but there is one side of life which the government has not considered yet it is the most crucial part of this initiative of self-employment.</motion.p>
                <motion.p variants = {item}>Graduates who come from rich families and able to get enough capital to invest in business and generate more jobs, are all absorbed into the working class because their parents have access to such employment chances as well as facilitating them to do better courses at the universities. This leaves the people with capital in well-paying office jobs and on the other hand leaving graduates from subsistence families with no capital and no jobs. Some graduates from subsistence families have ended up opting for, roadside vending, operating chapatti stalls, financially struggling retail shops, and ridding Boda Boda after selling land to buy the motorcycle. Others ride Boda Bodas that belong to the working-class fellows who at the end of the day need some daily payments which is hard to raise in some bad days. </motion.p>
                <motion.p variants = {item}>With all these challenges the only solution is creating some apprenticeship program where these fresh graduates can be absorbed and given chance to develop their professional skills, build the CV and also increase chances of access to internal adverts that run in different companies in Uganda. </motion.p>
                <motion.p variants = {item}>Considering all the above challenges faced by the unemployed graduates, it becomes very clear that you cannot solve issues of unemployed graduates in Uganda unless you solve issues affecting subsistence farmers in Uganda since most of the suffering Ugandan youths are from subsistence families. </motion.p>
                <motion.p variants = {item}>There is a workforce of uncountable graduates suffering with no jobs, and there are 35milliion Ugandans living in rural areas of Uganda suffering without skilled personnel to help them access services. There must be something wrong if we don’t unite and solve this predicament.</motion.p>
                <motion.p variants = {item}>Join UGAFo today and support the UGAFo cohorts both financially and materially for equitable community development.</motion.p>
        </motion.div>
    )
}

export default Formation