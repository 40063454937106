import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import RegApprentice from './RegApprentice'
import RegAmbassodor from './RegAmbassodor'
import RegBuyer from './RegBuyer'
import RegTraining from './RegTraining'
import RegFarmer from './RegFarmer'
import RegStaff from './RegStaff'
import{ motion } from "framer-motion"
import { container, item } from './Formation'
import "../css/Register.css"
import RegInternational from './RegInternational'

const Welcome = () => {
    return(
        <motion.div 
            variants={container}
            initial="hidden"
            animate="show"
            className='registerInfo'>
                <motion.h2 variants = {item}>Apprentice</motion.h2>
                <motion.div variants = {item}>
                    <p>welcome page here</p>
                </motion.div>
                <motion.h2 variants = {item}>UGAFo Ambassador</motion.h2>
                <motion.div variants = {item}>
                    <p>welcome page here</p>
                </motion.div>
                <motion.h2 variants = {item}>Buyer</motion.h2>
                <motion.div variants = {item}>
                    <p>welcome page here</p>
                </motion.div>
                <motion.h2 variants = {item}>Training Center</motion.h2>
                <motion.div variants = {item}>
                    <p>welcome page here</p>
                </motion.div>
                <motion.h2 variants = {item}>Staff Recruitment</motion.h2>
                <motion.div variants = {item}>
                    <p>welcome page here</p>
                </motion.div>
                <motion.h2 variants = {item}>Farmer</motion.h2>
                <motion.div variants = {item}>
                    <p>welcome page here</p>
                </motion.div>
        </motion.div>
    )
}

const Register = () => {
    const roles = ["Home", "apprentice", "UGAFo Ambassador", "buyer", "training center", "staff recruitment", "farmer", "international Apprenticeship"]
    const [active, setActive] = useState(null)
    const handleClick = (e) => {
        setActive(e)
    }

    useEffect(()=>{
        setActive(0)
    },[])
    return (
        <>
            <Header />
            <div className="home-container">
                <div className="side-panel">
                    <h2 className='pt-2 pb-2 fw-bold'>Registration Type</h2>
                    {roles.map((item, index) => (
                        <Link onClick={()=>handleClick(index)} className={active === index ? 'activeSelection':''} key={index}>{item}</Link>
                    ))}
                </div>
                <div className="scrollable-content">
                    { active === 0 && <Welcome /> }
                    { active === 1 && <RegApprentice /> }
                    { active === 2 && <RegAmbassodor /> }
                    { active === 3 && <RegBuyer /> }
                    { active === 4 && <RegTraining /> }
                    { active === 5 && <RegStaff /> }
                    { active === 6 && <RegFarmer /> }
                    { active === 7 && <RegInternational /> }
                </div>
            </div>
        </>
    )
}

export default Register