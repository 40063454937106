import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='d-block d-md-flex justify-content-between footer'>
      <div>
        <p>&copy; {new Date().getFullYear()} United Graduates Apprenticeship Foundation (UGAFo)</p>
        <small>Terms & Conditions apply</small>
      </div>
      <div>
        <p>powered by <Link className='text-decoration-none text-white fw-bolder' to={"https://cynaut-tech.com"}>Cynaut Technologies</Link></p>
      </div>
    </div>
  )
}

export default Footer