import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const Vision = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='vision'>
            <motion.h2 variants = {item}>VISION</motion.h2>
            <motion.p variants = {item}>A thriving Uganda where graduates are skilled, rural communities flourish, and sustainable farming practices drive prosperity. UGAFo envisions a nation where fair opportunities prevail, farmers' rights are protected, and innovation hubs foster entrepreneurial success. We aspire to be the catalyst for positive change, shaping a future where every district is a center for agricultural excellence and value addition, contributing to a resilient and inclusive Ugandan society.</motion.p>
    </motion.div>
  )
}

export default Vision