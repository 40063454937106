import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import { motion } from 'framer-motion'
import { useLoginContext } from './LoginContext';
import useFetch from './useFetch';
import { domain } from './Domain';
import "../css/Dashboard.css"
import useApiRequest from './useApiRequest'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Account from './Account';


const options = { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' };

const Welcome = ({ jobs, jobsApplied, userInfo, refreshJobsApplied, setRefreshJobsApplied }) => {
  const { postRequest } = useApiRequest()

  const handleApply = async(id) => {
    const formData = new FormData()
    formData.append("applicant", userInfo.id)
    formData.append("job", id)

    const response = await postRequest(`${domain}/job-applications`, formData)
    console.log(response)
    setRefreshJobsApplied(!refreshJobsApplied)
  }

  const notAccepted = ['Admin', 'TrainingCenter']

  return(
    <>
      <div className='welcome pt-2 pb-2'>
        <motion.h1 initial={{y: "150%"}} animate={{y: 0}} transition={{delay:.2, duration: 1}}>Available<span> Job Opportunities at (UGAFo)</span></motion.h1>
      </div>
      {jobs && jobs.map(item => (
        <motion.div className='jobs' initial={{y: 100}} animate={{y: 0}} transition={{delay:.1, duration: 1}} key={item.id}>
          <h2>{item.name} <span className='text-muted'>posted on {new Date(item.posted).toLocaleString('en-US', options)}</span></h2>
          <p>{item.description}</p>
          {userInfo.role && notAccepted.includes(userInfo.role) ? (
            <button disabled className='btn btn-secondary'>Application Not Possible</button>
          ) : (
            jobsApplied && jobsApplied.some(appliedJob => appliedJob.job.id === item.id) ? (
              <button className='btn btn-danger' disabled>Job Already Applied For</button>
            ) : (
              <motion.button onClick={() => handleApply(item.id)} whileHover={{scale: 1.05}} whileTap={{scale: 0.95}}>Apply To Job</motion.button>
            )
          )}
        </motion.div>
      ))}
    </>
  )
}

const Tabled = ({regCategory, data, refreshJobApplications, setRefreshJobApplications, refresh, setRefresh, userInfo}) => {
  const { putRequest, getRequest, postRequest } = useApiRequest()
  const cat1 = ["S/No","First Name","Last Name", "Email Address", "Contact", "Gender", "Institution", "Year", "District", "CV", "Applicant Notified"]
  const cat2 = ["S/No","First Name","Last Name", "Email Address", "Contact", "Gender", "Years", "District"]
  const cat3 = ["S/No","Name","Email Address", "Contact", "Year", "District"]
  const cat4 = ["S/No","First Name", "Last Name", "Email Address", "Contact", "Gender", "NIN", "Year", "District", "Products"]
  const cat5 = ["S/No","Name", "Application Date", "Status"]
  const cat6 = ["S/No","Job Title", "Applicant Name", "Application Date", "status", "Action To Perform"]

  const [show, setShow] = useState(false);
  const [response, setResponse] = useState(null)
  const [showForm, setShowForm] = useState(false);
  const [selected, setSelected] = useState(null)
  const initial = {
    subject: "",
    body: ""
  }
  const [emailData, setEmailData] = useState(initial);

  const handleInputChange = (event) =>{
    const {name, value} = event.target
    setEmailData({
      ...emailData,
      [name]: value
  });
  }
  const handleClose = () => {
    setShow(false)
    setResponse(null)
  };
  const handleShow = async(e) => {
    setShow(true)
    const response = await getRequest(`${domain}/user-info?id=${e.applicant.id}`)
    setResponse(response)
  };

  const handleShowForm = async(item) => {
    setShowForm(true)
    setSelected(item['item'])
  };
  const handleCloseForm = () => {
    setShowForm(false)
    setSelected(null)
    setEmailData(initial)
  };

  const sendNotification = async() => {
    const formData = new FormData()
    formData.append("subject", emailData.subject)
    formData.append("body", emailData.body)
    formData.append("userId", selected.owner.id)
    formData.append("email", selected.owner.email)
    formData.append("regCategory", selected.regCategory)
    formData.append("contact", selected.contact)
    const response = await postRequest(`${domain}/notifications`, formData)
    console.log(response)
    setRefresh(!refresh)
  }

  const handleUpdate = async(e, item) => {
    const formData = new FormData()
    formData.append("applicant", item.applicant.id)
    formData.append("job", item.job.id)
    formData.append("status", e)
    const response = await putRequest(`${domain}/job-applications/${item.id}`, formData)
    console.log(response)
    setRefreshJobApplications(!refreshJobApplications)
  }

  const handleCv = (e) => {
    let newWin = window.open(e, "pdf show", "width=800,height=600");
    // Calculate the position to center the new window
    const parentWidth = window.innerWidth;
    const parentHeight = window.innerHeight;
    const newWinWidth = 800; // Width of the new window
    const newWinHeight = 600; // Height of the new window
    const leftPos = (parentWidth - newWinWidth) / 2;
    const topPos = (parentHeight - newWinHeight) / 2;

    // Move the new window to the calculated position
    newWin.moveTo(leftPos, topPos);
    
    newWin.focus();
  }
  return(
    <div>
      <p className='fw-bold text-muted'>{regCategory}</p>
      <table>
        <thead>
          <tr>
            {(regCategory === "Apprentice Applications" || regCategory === "UGAFo Ambassador Applications" || regCategory === "Staff recruitment Applications" || regCategory === "International Apprenticeship Applications") &&
              cat1.map(item => (
                <th key={item}>{item}</th>
              ))
            }
            {(regCategory === "Buyer Applications") &&
              cat2.map(item => (
                <th key={item}>{item}</th>
              ))
            }
            {(regCategory === "Training Center Applications") &&
              cat3.map(item => (
                <th key={item}>{item}</th>
              ))
            }
            {(regCategory === "Farmer Applications") &&
              cat4.map(item => (
                <th key={item}>{item}</th>
              ))
            }
            {(regCategory === "My Job Applications") &&
              cat5.map(item => (
                <th key={item}>{item}</th>
              ))
            }
             {(regCategory === "Job Applications") &&
              cat6.map(item => (
                <th key={item}>{item}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {(regCategory === "Apprentice Applications" || regCategory === "UGAFo Ambassador Applications" || regCategory === "Staff recruitment Applications" || regCategory === "International Apprenticeship Applications") && data && data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.owner.first_name}</td>
              <td>{item.owner.last_name}</td>
              <td>{item.owner.email}</td>
              <td>{item.contact}</td>
              <td>{item.gender}</td>
              <td>{item.institution}</td>
              <td>{item.year}</td>
              <td>{item.district}</td>
              <td><Link onClick={()=> handleCv(domain+item.cv)} >{"View CV"}</Link></td>
              <td>{item.notified ? "Already Notified" : userInfo.role === "Admin" ? <button className='btn btn-sm btn-outline-primary' onClick={(e)=>handleShowForm({item})}>Send Confirmation</button> : "Pending"}</td>
            </tr>
          ))}
          {(regCategory === "Buyer Applications") && data && data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.owner.first_name}</td>
              <td>{item.owner.last_name}</td>
              <td>{item.owner.email}</td>
              <td>{item.contact}</td>
              <td>{item.gender}</td>
              <td>{item.year}</td>
              <td>{item.district}</td>
            </tr>
          ))}
          {(regCategory === "Training Center Applications") && data && data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.owner.email}</td>
              <td>{item.contact}</td>
              <td>{item.year}</td>
              <td>{item.district}</td>
            </tr>
          ))}
          {(regCategory === "Farmer Applications") && data && data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.owner.first_name}</td>
              <td>{item.owner.last_name}</td>
              <td>{item.owner.email}</td>
              <td>{item.contact}</td>
              <td>{item.gender}</td>
              <td>{item.nin}</td>
              <td>{item.year}</td>
              <td>{item.district}</td>
              <td>{item.products}</td>
            </tr>
          ))}
          {(regCategory === "My Job Applications") && data && data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.job.name}</td>
              <td>{new Date(item.created).toLocaleString('en-US', options)}</td>
              <td>{item.status}</td>
            </tr>
          ))}
          {(regCategory === "Job Applications") && data && data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.job.name}</td>
              <td>{item.applicant.first_name} {item.applicant.last_name}</td>
              <td>{new Date(item.created).toLocaleString('en-US', options)}</td>
              <td>{item.status}</td>
              <td>
                <button className='btn btn-sm btn-info text-white' onClick={()=>handleUpdate("Pending", item)}>Pending</button>
                <button className='btn btn-sm btn-warning text-white ms-2' onClick={()=>handleUpdate("Under Review", item)}>Under Review</button>
                <button className='btn btn-sm btn-primary ms-2' onClick={()=>handleUpdate("Approved", item)}>Approved</button>
                <button onClick={() => handleShow(item)} className='btn btn-sm btn-danger ms-2'>View Information</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className='d-none'>{JSON.stringify(data)}</p>
      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>{response && `${response.owner.first_name} ${response.owner.last_name}`} <small className='text-muted fw-light'>{response && response.owner.email}</small></Modal.Title>
        </Modal.Header>
        {response &&
        <Modal.Body className='d-flex gap-4'>
          <div className='w-50'>
            <p className='fw-bold fs-6 text-muted border-bottom pb-2'>Applicant Category<br /><small className='fw-normal'>{response.regCategory}</small></p>
            <p className='fw-bold fs-6 text-muted border-bottom pb-2'>Date of Birth<br /><small className='fw-normal'>{response.dob}</small></p>
            <p className='fw-bold fs-6 text-muted border-bottom pb-2'>Gender<br /><small className='fw-normal'>{response.gender}</small></p>
            <p className='fw-bold fs-6 text-muted border-bottom pb-2'>Course Persued<br /><small className='fw-normal'>{response.program}</small></p>
            <p className='fw-bold fs-6 text-muted border-bottom pb-2'>Year of Completion<br /><small className='fw-normal'>{response.year}</small></p>
            <p className='fw-bold fs-6 text-muted border-bottom pb-2'>National Identification Number<br /><small className='fw-normal'>{response.nin}</small></p>
            <p className='fw-bold fs-6 text-muted'>Phone Number<br /><small className='fw-normal'>{response.contact}</small></p>
          </div>
          <div className='w-25 flex-grow-1'>
            <img src={domain+response.profile} className='w-100' alt='user image'/>
            <p className='fw-bold fs-6 text-muted pt-2'>Residence of Applicant</p>
            <small>District - {response.district} <br />SubCounty - {response.subcounty} <br />Village - {response.village} <br />Cell - {response.cell}</small>
            <p className='pt-2 fs-6 fw-bold'><Link to={domain+response.cv} target='__blank' className='text-danger'>View | Download Applicant CV</Link></p>
          </div>
        </Modal.Body>
        }
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Email and SMS Modal Form Here */}
      <Modal size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered show={showForm} onHide={handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>Send Message | Email to {selected && `${selected.owner.first_name} ${selected.owner.last_name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className='fw-bold text-muted'>Subject</label>
          <input type='text' placeholder='subject' name='subject' onChange={handleInputChange} value={emailData.subject} />
          <label className='fw-bold text-muted'>Message Body</label>
          <textarea rows={5} placeholder='Message Body / Content' name='body' onChange={handleInputChange} value={emailData.body}></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseForm}>
            Close
          </Button>
          <Button onClick={sendNotification} variant="primary" disabled={emailData.subject === "" || emailData.body === ""}>
            Send Message
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

const Dashboard = () => {
  const [reload, setReload] = useState(false)
  const [regCategory, setRegCategory] = useState("Apprentice")
  const { isLoggedIn, setIsLoggedIn, userInfo, setUserInfo } = useLoginContext();
  const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/applications?regCategory=${regCategory}`);
  const {data:jobs, loading:oadingJobs, error:errorJobs, refresh:refreshJobs, setRefresh:setRefreshJobs} = useFetch(`${domain}/jobs`);
  const {data:jobsApplied, loading:loadingJobsApplied, error:errorJobsApplied, refresh:refreshJobsApplied, setRefresh:setRefreshJobsApplied} = useFetch(`${domain}/job-applications?id=${userInfo.id}`);
  const {data:jobApplications, loading:loadingJobApplications, error:errorJobApplications, refresh:refreshJobApplications, setRefresh:setRefreshJobApplications} = useFetch(`${domain}/job-applications`);
  const [active, setActive] = useState(null)
  const roles = ["Home", "Apprentice Applications", "UGAFo Ambassador Applications", "Buyer Applications", "Training Center Applications", "Staff recruitment Applications", "Farmer Applications", "International Apprenticeship Applications"]
  const rolesTrainingCenter = ["Home", "Apprentice Applications"]
  const handleClick = (e) => {
    if(e === 0){
      setActive(null)
    }else{
      if(e === 1){setRegCategory("Apprentice")}
      else if (e === 2){setRegCategory("Ambassodor")}
      else if(e === 3){setRegCategory("Buyer")}
      else if(e === 4){setRegCategory("TrainingCenter")}
      else if(e === 5){setRegCategory("Staff")}
      else if(e === 6){setRegCategory("Farmer")}
      else if(e === 7){setRegCategory("InternationalApprentice")}
      setActive(e)
    }
  }

  const isInRange = (number, min, max) => {
    return number >= min && number <= max
  }
  return (
    <div>
        <Header />
        <div className="home-container">
          <div className="side-panel">
            {userInfo.role === "Admin" && roles.map((item, index) => (
              <Link onClick={()=>handleClick(index)} className={active === index ? 'activeSelection':''} key={index}>{item}</Link>
            ))}
            {userInfo.role === "TrainingCenter" && rolesTrainingCenter.map((item, index) => (
              <Link onClick={()=>handleClick(index)} className={active === index ? 'activeSelection':''} key={index}>{item}</Link>
            ))}
            {userInfo.role === "Admin" && <Link onClick={()=>handleClick(8)} className={active === 8 ? 'activeSelection':''}>Job Applications</Link>}
            {(userInfo.role !== "Admin" || userInfo.role !== "Admin") && <Link onClick={()=>handleClick(0)} className={active === 0 ? 'activeSelection':''}>Homedddd</Link>}
            {userInfo.role !== "Admin" && <Link onClick={()=>handleClick(9)} className={active === 9 ? 'activeSelection':''}>My Job Applications</Link>}
            <Link onClick={()=>handleClick(10)} className={active === 10 ? 'activeSelection':''}>My Account</Link>
            <Link onClick={()=>handleClick(11)}>Logout</Link>
          </div>
          <div className="scrollable-content">
            {active === null ? 
              <Welcome jobs={jobs} jobsApplied = {jobsApplied} userInfo={userInfo} refreshJobsApplied={refreshJobsApplied} setRefreshJobsApplied={setRefreshJobsApplied} />
              :
              <>
                { isInRange(active, 1, 7) && <Tabled regCategory = {roles[active]} data={data} refresh={refresh} setRefresh = {setRefresh} userInfo={userInfo} /> }
              </>
            }
            {active === 8 && userInfo.role === "Admin" && <Tabled regCategory = {"Job Applications"} data={jobApplications} refreshJobApplications={refreshJobApplications} setRefreshJobApplications = {setRefreshJobApplications} />}
            {active === 9 && <Tabled regCategory = {"My Job Applications"} data={jobsApplied} />}
            {active === 10 && <Account regCategory = {"My Account Information"} />}

          </div>
        </div>
    </div>
  )
}

export default Dashboard