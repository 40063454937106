import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const BenefitsGraduates = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='benefits'>
            <motion.h2 variants = {item}>BENEFITS OF BEING A MEMBER OF UGAFO FOR GRADUATES</motion.h2>
            <motion.ul variants = {item}>
                <li>Getting apprenticeship placements for professional skills development.</li>
                <li>Getting an on-job training certificate after serving in the given position for a period of one year.</li>
                <li>Using the name of the immediate supervisor in the given organization as a referee for every application made during the apprenticeship period. The foundation will also issue a recommendation letter to every member applying for any given job.</li>
                <li>Receiving job alerts on your phone every day in line with your qualification through the UGAFo YouTube channel.</li>
                <li>Offering collective printing and delivery of applications for graduates who are in hard-to-reach areas at an affordable rate.</li>
                <li>Following up on all applications delivered by our members to understand reasons for non-selection. If there is any legal need, the graduate gets helped by the foundation. This will apply to all applications delivered to district service commission. The commission will be required to display the performance of those who were successful with the interview as well as informing those that did not go through. This will aim to compel the government to change the procedure used by the district service commission for fair treatment of all candidates in Uganda.</li>
                <li>Offering legal services to members of our foundation in areas of gender-based violence at workplaces and institutions of higher learning.</li>
                <li>Getting access to UGAFo research and innovation hubs which will be aimed to build from what the graduate has in mind and putting it on trial and testing the results for innovation and discovery.</li>
                <li>Access to credit to supplement tuition in case there is a need.</li>
            </motion.ul>
    </motion.div>
  )
}

export default BenefitsGraduates