import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const Pillar4 = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='pillarDetails'>
            <motion.h2 variants = {item}>THE UGAFO CREDIT SCHEME. </motion.h2>
            <motion.p variants = {item}>In the present generation where peasants and unemployed graduates are becoming targets by all people whose dream is only exploitation but not equitable community development, UGAFo credit scheme comes in as a shield to protect peasants, UGAFo ambassadors and graduates from this exploitation. This will be solved using target products that can solve this problem tactically without hurting the lending institution and the client. </motion.p>
            <motion.h2 variants = {item}>PRODCUTS UNDER THE UGAFO SCHEME</motion.h2>
            <motion.h3 variants = {item}>STIPEND BASED LOANS</motion.h3>
            <motion.p variants = {item}>This is a special loan product under the UGAFo credit scheme that is specially formulated for UGAFo ambassadors and the UGAFo apprentices</motion.p>
            <motion.p variants = {item}>Eligibility of this loan product depends on two things; </motion.p>
            <motion.ul variants = {item}>
                <li>Must be a member of UGAFo as a UGAFo ambassador, or must be doing apprenticeship under the UGAFo credit scheme.</li>
                <li>Must be deployed at a UGAFo cohort or training center as an apprentice or admitted in a higher institution of learning.</li>
            </motion.ul>
            <motion.p variants = {item}>Stipend based loans will be limited to maximum of 500000/= ugx to those operating with in Uganda and 5,000,000/= ugx to those deployed in international farms. </motion.p>
            <motion.h3 variants = {item}>Securities</motion.h3>
            <motion.p variants = {item}>Considering that most of the graduates and continuing students have no access to material wealth like land, houses or cars, UGAFo will consider third party form of securities. Third part in this case can be parents, uncle, aunt, brothers and sisters. </motion.p>
            <motion.h3 variants = {item}>Interest rates</motion.h3>
            <motion.p variants = {item}>UGAFo will consider 25% per annum as its standard interest rate for the stipend-based loan product.</motion.p>
            <motion.h3 variants = {item}>Payback period</motion.h3>
            <motion.p variants = {item}>UGAFo will consider any period in multiple of three as the payment period for this loan product as long as it doesn’t go beyond one year.  </motion.p>
            <motion.h3 variants = {item}>Processing period</motion.h3>
            <motion.p variants = {item}>All UGAFo loans will be considered as emergency loans and will be only extended in times of great need. This will make the processing time of UGAFo loans shorter between 8hrs to 24hrs depending on availability of the necessary documents. </motion.p>
            <motion.h3 variants = {item}>Guarantors</motion.h3>
            <motion.p variants = {item}>For anyone to access this loan product, he or she must present 3 guarantors who must be parents, brother, sister, aunt, uncle or chairperson lc1 and must be having an account with the UGAFo credit scheme. </motion.p>
            <motion.p variants = {item}>In addition to the above three guarantors, the UGAFo ambassadors will have to secure stamp and signature of the university dean of students and the current guild president of the given institution or university.</motion.p>
            <motion.p variants = {item}>For the UGAFo apprentices, in addition to the above, will have to secure stamp and signature of the immediate supervisor and human resource manager or branch manager or the given training center. </motion.p>
            <motion.h3 variants = {item}>Savings scheme</motion.h3>
            <motion.p variants = {item}>All UGAFo apprentices will have 50000 shillings saved on their UGAFo credit scheme accounts every month and will be accessed after a period of one year.</motion.p>
            <motion.p variants = {item}>For those who will get jobs somewhere else during the course of the training, their money will be disbursed and accessed at once upon handing over at their area of training. </motion.p>
            <motion.h3 variants = {item}>What happens on default?</motion.h3>
            <motion.p variants = {item}>In case a graduate or ambassador fails to pay back the money in the time agreed upon, the guarantors will be contacted at once and compelled to have this money settled. </motion.p>
            <motion.h3 variants = {item}>What happens if a member dies with a loan?</motion.h3>
            <motion.p variants = {item}>All UGAFo loans will be insured. Demise of any client will not cause any impact on his family as far as paying back this loan is concerned. The insurance will step in and pay back the loan.</motion.p>
            <motion.h3 variants = {item}>What happens if a member gets incapacitated while servicing a loan?</motion.h3>
            <motion.p variants = {item}>All UGAFo loans will be insured. Incapacitation of the UGAFo client will not cause any impact on his family as far as paying back this loan is concerned. The insurance will step in and pay back the loan. </motion.p>
            <motion.h2 variants = {item}>AGRICULTURAL LINE OF CREDIT</motion.h2>
            <motion.p variants = {item}>In the realm of agriculture, timely access to funds can make the difference between a bountiful harvest and missed opportunities. The Agricultural Line of Credit, or ALC, emerges as a beacon of hope. </motion.p>
            <motion.p variants = {item}>This mechanism offers a revolving credit facility from UGAFo. Rather than a lump sum, farmers can draw upon this credit line as needed, up to a certain limit. </motion.p>
            <motion.p variants = {item}>This tailor-made solution addresses the specific cash flow demands of farming, adjusting to the ebbs and flows of planting and harvest seasons.</motion.p>
            <motion.p variants = {item}>This credit line's purpose goes beyond mere financial support. Agriculture, with its myriad uncertainties—be it unpredictable weather or market dynamics—demands a robust safety net. </motion.p>
            <motion.p variants = {item}>The ALC provides this, ensuring that essential operations, like purchasing seeds or equipment repairs, don't stall due to lack of funds. </motion.p>
            <motion.p variants = {item}>In essence, this credit facility forms the backbone of consistent farming operations, allowing agricultural endeavours to thrive even in the face of unforeseen challenges.</motion.p>
            <motion.h3 variants = {item}>Who is eligible?</motion.h3>
            <motion.p variants = {item}>All members of UGAFo credit scheme qualify for this loan product without limit as long as the money applied for is for the betterment of the farmer and will be able to pay back with ease. The main focus of this loan product is to protect farmers from challenges that shock their capacity of production.  </motion.p>
            <motion.h3 variants = {item}>Amount of money that can be accessed by a member</motion.h3>
            <motion.p variants = {item}>The members will be able to access credit from 50,000 shillings to 5,000,000 shillings for first time borrowers and the amount will be open ended for repeating clients with good loan repayment history.</motion.p>
            <motion.h3 variants = {item}>Securities</motion.h3>
            <motion.p variants = {item}>UGAFo will only consider land as the only security since we are dealing with farmers and land is the only resource that all farmers have access to. </motion.p>
            <motion.h3 variants = {item}>Interest rates </motion.h3>
            <motion.p variants = {item}>UGAFo will consider 25% per annum as its standard interest rate for the stipend-based loan product.</motion.p>
            <motion.p variants = {item}>Payback period</motion.p>
            <motion.p variants = {item}>UGAFo will consider any period in multiple of three as the payment period for this loan product as long as it doesn’t go beyond one year.  </motion.p>
            <motion.p variants = {item}>Processing period</motion.p>
            <motion.p variants = {item}>All UGAFo loans will be considered as emergency loans and will be only extended in times of great need. This will make the processing time of UGAFo loans shorter between 8hrs to 24hrs depending on availability of the necessary documents. </motion.p>
            <motion.h3 variants = {item}>Guarantors</motion.h3>
            <motion.p variants = {item}>The farmer must present three guarantors who are mentally well and above 18 years of age.</motion.p>
            <motion.p variants = {item}>The guarantors must be members of the UGAFo credit scheme.</motion.p>
            <motion.p variants = {item}>The guarantors must have lived in that given area for a period not less than three years. </motion.p>
            <motion.p variants = {item}>The chairperson lc1 must be able to identify the given guarantors as residents of his cell.</motion.p>
            <motion.p variants = {item}>The guarantors must be harvesting enough food for their children and with some surplus for sell. </motion.p>
            <motion.h3 variants = {item}>Activities targeted for funding with the ALC</motion.h3>
            <motion.p variants = {item}>Agricultural line of credit considers all aspects that happen in lives of farmers. This rages from buying inputs from the gardens, farm labor, farm equipment etc., up to marketing. The farmer may also need credit for investment e.g. buying land or establishing an estate. The farmers may also get emergencies such as sickness, calamity or any other need for money not forgetting school fees. UGAFo will stand with the farmer in all aspects of life. </motion.p>
            <motion.h3 variants = {item}>What happens on default?</motion.h3>
            <motion.p variants = {item}>In case a graduate or ambassador fails to pay back the money in the time agreed upon, the guarantors will be contacted at once and compelled to have this money settled. </motion.p>
            <motion.h3 variants = {item}>What happens if a member dies with a loan?</motion.h3>
            <motion.p variants = {item}>All UGAFo loans will be insured. Demise of any client will not cause any impact on his family as far as paying back this loan is concerned. The insurance will step in and pay back the loan. </motion.p>
            <motion.h3 variants = {item}>What happens if a member gets incapacitated while servicing a loan?</motion.h3>
            <motion.p variants = {item}>All UGAFo loans will be insured. Incapacitation of the UGAFo client will not cause any impact on his family as far as paying back this loan is concerned. The insurance will step in and pay back the loan. </motion.p>
    </motion.div>
  )
}

export default Pillar4