import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const Mission = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='mission'>
            <motion.h2 variants = {item}>MISSION STATEMENT</motion.h2>
            <motion.p variants = {item}>Empowering Uganda through skills and sustainable development, UGAFo equips graduates, uplifts rural communities, and advocates for eco-friendly farming. We bridge gaps, ensure fair treatment in recruitment, and champion farmers' rights. UGAFo controls price fluctuations, connects farmers globally, and facilitates legal services. We foster a transparent and collaborative community, transforming every district into a hub for agricultural value addition. </motion.p>
    </motion.div>
  )
}

export default Mission