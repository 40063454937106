import React from 'react'
import "../css/Processing.css"

const Processing = () => {
  return (
    <div className='processingDiv'>
        <i class="fa-solid fa-mobile"></i>
        <h2>Please Wait While We <br />Upload Your Content</h2>
        <p className='text-danger fw-bold'><small>status of this page is updated <br />automatically</small></p>
        <div class="lds-dual-ring"></div>
    </div>
  )
}

export default Processing