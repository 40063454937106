import React from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import { motion } from 'framer-motion'
import "../css/Contact.css"

const Contact = () => {
  return (
    <div>
      <Header />
        <div className='row adress_cont m-auto'>
          <motion.div initial = {{opacity: 0, y: 100}} whileInView={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}} class="col-md-6">
              <h4>Contact Information</h4>
              <p>Welcome to our platform! We strive to provide you with the best experience possible. Our team is dedicated to delivering high-quality services tailored to meet your needs.</p>
              <div class="d-flex align-items-center gap-4">
              <i class="fa fa-road" aria-hidden="true"></i>
              <div className='w-100'>
                  <h5>United Graduates Apprenticeship Foundation (UGAFo)</h5>
                  <p>P.O Box 114045 Wakiso (U)</p>
                  <hr />
              </div>
          </div>
          <div class="d-flex align-items-center gap-4">
              <i class="fa fa-user" aria-hidden="true"></i>
              <div className='w-100'>
                  <h5>+256-779-873100</h5>
                  <p>Mon-Fri 8:30am-6:30pm</p>
                  <hr/>
              </div>
          </div>
          <div class="d-flex align-items-center gap-4">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <div className='w-100'>
                  <h5>ugafo.ug.org@gmail.com</h5>
                  <p>24/7 online support</p>
                  <hr />
              </div>
          </div>
        </motion.div>
        <motion.div initial = {{opacity: 0, y: -100}} whileInView={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}} className='col-md-6'>
            <iframe width="100%" className='bg-light' height="400" frameborder="0" style={{border:0}} src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50&amp;q=wakiso" allowfullscreen="" title='location'></iframe>
        </motion.div>
        </div>
    </div>
  )
}

export default Contact