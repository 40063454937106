import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const BenefitsPeasant = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='benefits'>
            <motion.h2 variants = {item}>BENEFITS OF BEING A MEMBER OF UGAFo FOR PEASANTS</motion.h2>
            <motion.ul variants = {item}>
                <li>Getting access to the agricultural line of credit.</li>
                <li>Having access to the digital farmers’ markets by farming together, storing produce together, packaging together, branding together at the district level, and selling together to both national and international markets.</li>
                <li>Price protection, whereby all members of the foundation will be able to reaccess the products they sold through our foundation at a rate not beyond 300 shillings only per kilo. This will protect farmers from exploitation caused by middlemen who buy agricultural products at a very cheap price from the farmers and sell them at an exorbitant price after hoarding them for a short period of time.</li>
                <li>Farmers will also get a chance of selling directly to final consumers and enjoy better prices for their products.</li>
                <li>Control over price fluctuation. After registering all farmers in Uganda and having control over all agricultural markets and transport for agricultural commodities, the farmers will be the price makers, not the price takers, by creating a monopoly of their produce. The farmers together with the foundation members will set prices for different commodities depending on season and set the price favorable for their income. The price will be general throughout the whole country.</li>
                <li>Free extension services offered by our agricultural officers in the foundation as well as the credit officers handling the agricultural line of credit.</li>
                <li>Getting inputs such as seeds, fertilizers, and other agricultural equipment such as all garden tools directly from factories to avoid exploitation and alteration done by input vendors.</li>
                <li>Protection from quality alteration which is always done by the middlemen and end up destroying the international markets for farmers.</li>
                <li>Controlling the selling standards of all agricultural produce by using weighing scales as a standard method throughout the whole country.</li>
                <li>Getting subsidized legal services in cases concerning land and marketing of the agricultural produce.</li>
                <li>Putting an end to auctioning of agricultural products in Uganda which has been the common game done by the middlemen who move through the whole country hawking and auctioning agricultural products especially in Kampala.</li>
            </motion.ul>
    </motion.div>
  )
}

export default BenefitsPeasant