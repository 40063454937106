import React, { useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { domain } from './Domain';
import { motion, AnimatePresence } from 'framer-motion';

const PasswordResetForm = () => {
  const { slug } = useParams();
  const [isPending, setIsPending] = useState(false);
  const [login, setLogin] = useState(false);
  const regRefSuccess = useRef();
  const [errors, setErrors] = useState();
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    regRefSuccess.current.style.display = "block";
    regRefSuccess.current.textContent = "Requesting password reset ..."
    let form = e.target;
    let data = new FormData(form);
    const requestOptions = {
        method: 'POST',
        cors: "cors",
        body: data
    };
    fetch(`${domain}/accounts/password-reset/${slug}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
        form.reset();
        regRefSuccess.current.textContent = data['message']
        setTimeout(function(){
            regRefSuccess.current.textContent = '';
            regRefSuccess.current.style.display = "none";
            //navigate("/accounts/login")
        },5000);
        setIsPending(false);
        //console.log(data);

        if (data['message'].toLowerCase().includes("successfully!".toLowerCase())) {
            setLogin(true)
        }
    })
    .catch((err) => console.log(err))
  };

  return (
    <motion.form 
        initial={{y: 500}}
        animate={{y: 150}}
        onSubmit={handleSubmit} className='forgotPassword'>
        <h2 className='fs-5 pt-2 lead fw-bold fg text-success '>Reset Your Password</h2>
        <p ref={regRefSuccess} className='text-success fw-bold m-0' style={{display:"none"}}></p>
        <div>
            <label htmlFor="currentCode" className='pt-2' style={{fontSize: "0.875rem"}}>Secret Code Provided:</label>
            <input
                type="number"
                id="currentCode"
                name="code"
                className='form-control'
                placeholder='code sent to your email'
                required />
        </div>
        <div>
            <label htmlFor="newPassword" className='pt-2' style={{fontSize: "0.875rem"}}>New Password:</label>
            <input
                type="password"
                id="newPassword"
                name="newPassword"
                className='form-control'
                placeholder='New password'
                required />
        </div>
        <div>
            <label htmlFor="confirmNewPassword" className='pt-2' style={{fontSize: "0.875rem"}}>Confirm New Password:</label>
            <input
                type="password"
                id="confirmNewPassword"
                name="confirmNewPassword"
                className='form-control'
                placeholder='Confirm Password'
                required />
                {errors && (
                    <p className="error-message fw-bold">{errors}</p>
                )}
        </div>
        <motion.button whileHover={{scale: 1.05}} whileTap={{scale: 0.9}} type="submit" className='btn btn-danger mt-2'>Change Password</motion.button>
        <AnimatePresence>
            {login === true && 
                <motion.button type='button' className='btn btn-primary mt-2 ms-2' whileHover={{scale: 1.05}} whileTap={{scale: 0.9}} initial={{opacity: 0, scale: 0}} animate={{opacity: 1, scale: 1}} ><Link className='text-white text-decoration-none' to={"/login"}> Login</Link></motion.button>
            }
        </AnimatePresence>
    </motion.form>
  );
};

export default PasswordResetForm;
