import React, {useState, useEffect} from 'react'
import Header from './Header';
import { motion, AnimatePresence } from 'framer-motion';
import useApiRequest from './useApiRequest';
import Processing from './Processing';
import "../css/Donate.css"
import Payment from './Payment';
import { domain } from './Domain';

const Donate = () => {
    const options = [
        "ugafo corhots", 
        "graduates scientific innovations", 
        "peasants’ access to improved seeds", 
        "tuition support for disadvantaged students in higher institutions of learning in Uganda"
    ]
    const [loading, setLoading] = useState(false)
    const [donationType, setDonationType] = useState(null)
    const [name, setName] = useState(null)
    const [amount, setAmount] = useState(null)
    const [number, setNumber] = useState("")
    const [email, setEmail] = useState("")
    const amounts = [10000, 20000, 50000, 100000, 500000, 1000000]
    const [other, setOther] = useState(false)
    const { postRequest, response } = useApiRequest()
    const [resp, setResponse] = useState(null)

    const handleClick = (e) =>{
        setDonationType(e.target.textContent);
    }

    const handleSubmit = async() => {
        setLoading(true)
        const formData = new FormData()
        formData.append("name",name)
        formData.append("amount",amount)
        formData.append("donationType",donationType)
        formData.append("number",number)
        formData.append("email",email)
        formData.append("response", JSON.stringify(resp))

        const response = await postRequest(`${domain}/donations`, formData)
        console.log(response)
        setLoading(false)
        //resetiing the form after submission
    }

    useEffect(()=>{
        if(resp !== null && resp.status === "successful"){
            //alert("The Transaction was successfull!")
            console.log(resp)
            handleSubmit()
        }

    },[resp])

    return (
        <div>
            <Header />
            <div className='donate'>
                <motion.h3 initial={{y: "10%"}} animate={{y: 0}} transition={{duration: 1}} className='fs-5 pt-4 jfw-bold'>Available Donation Categories</motion.h3>
                <motion.div initial={{y: "10%"}} animate={{y: 0}} transition={{delay:.2, duration: 1}} className='options'>
                    {options.map((item, index) => (
                        <motion.span className={donationType === item ? "activeItem" : ""} onClick={handleClick} whileHover={{scale: 1.02}} whileTap={{scale: 0.95}} key={index}>{item}</motion.span>
                    ))}
                </motion.div>
                <motion.form initial={{y: 10}} animate={{y: 0}} transition={{duration: 1}} onSubmit={handleSubmit}>
                    <label className='fw-bold'>Select Amount to Donate (Ugx)</label>
                    <div className='amounts'>
                        {amounts.map((item, index) => (
                            <motion.span onClick={()=>{setAmount(item); setOther(false)}} whileHover={{scale: 1.05}} whileTap={{scale: 0.9}} style={amount === item && {background: "teal"}} key={index}>{item.toLocaleString()}</motion.span>
                        ))}
                        <motion.span onClick={()=>{setOther(!other);setAmount("")}} whileHover={{scale: 1.05}} whileTap={{scale: 0.9}} style={amount === "" && {background: "teal"}}>Other</motion.span>
                    </div>
                    <AnimatePresence>
                        {donationType ?  
                            <motion.h3 key={donationType} className='fs-6 text-primary ps-3 border-start pt-4 pb-4' initial={{x: 100, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 100, opacity: 0}} transition={{duration: 0.5}}>Thanks for Opting to Donate Towards {donationType}</motion.h3>
                            :
                            <motion.h3 key={donationType} className='fs-6 ps-3 border-start pt-4 pb-4' initial={{x: 100, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 100, opacity: 0}} transition={{duration: 0.5}}>Please select a category</motion.h3>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {other && 
                            <motion.div initial={{opacity: 0, y:100}} animate={{opacity: 1, y: 0}} exit={{opacity: 0, y: 100}}>
                                <motion.label className='d-block'>Enter Donation Amount</motion.label>
                                <motion.input type='number' value={amount} onChange={(e)=>setAmount(e.target.value)} placeholder='Enter Amount to donate' />
                            </motion.div>
                        }
                    </AnimatePresence>
                    <input type='text' onChange={(e)=>setName(e.target.value)} value={name} placeholder='Donor Name' /><br />
                    <input type='email' onChange={(e)=>setEmail(e.target.value)} value={email} placeholder='Email address e.g johndoe@domain.com' /><br />
                    <input type='tell' onChange={(e)=>setNumber(e.target.value)} value={number} placeholder='Phone Number e.g. 0787000000' />
                    <label className='d-block'>Select Payment method</label>
                    <select className='d-block'>
                        <option>Select a payment method</option>
                        <option>MTN Mobile Money</option>
                        <option>Airtel Money</option>
                    </select>
                    {(donationType === null || name === "" || (number === "" || email === "")) ? 
                        <button className='btn btn-success disabled'>Make Donation</button>
                    :
                        <Payment 
                            amount={amount}
                            buttonText = {"Make Donation"}
                            number = {number}
                            email = {email}
                            setResponse={setResponse}
                        />
                    }
                    <motion.button whileHover={{scale: 1.05}} whileTap={{scale: 0.9}} className='btn btn-success d-none'>Donate</motion.button>
                </motion.form>
            </div>
            <AnimatePresence>
                {loading && (
                    <div className="processing-container">
                        <motion.div 
                            key={loading} 
                            initial={{ opacity: 0, scale: 0 }} 
                            animate={{ opacity: 1, scale: 1 }} 
                            exit={{ scale: 0, opacity: 0 }} 
                            className='processing'
                        >
                            <Processing />
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default Donate