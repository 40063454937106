import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

//
export default function Payment({number, email, amount, setResponse, buttonText}) {
   const config = {
    public_key: 'FLWPUBK-206d621df6b8e0cf8c81f1df10d7a0ec-X', //'FLWPUBK_TEST-52f747721c0d711c6456c88014efe9bd-X',
    tx_ref: Date.now(),
    amount: amount,
    currency: 'UGX',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: email,
      phone_number: number,
      name: 'john doe',
    },
    customizations: {
      title: 'UGAFo',
      description: 'UGAFo Registration Payment',
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  return  (
    <motion.button
      whileHover={{scale: 1.05}} whileTap={{scale: 0.9}}
      className='btn btn-success'
      type='button'
      onClick={() => {
          if(email === "" || number === ""){
              alert("please supply and email address or a phone number")
          }else{
              handleFlutterPayment({
                  callback: (response) => {
                      console.log(response);
                      setResponse(response);
                      closePaymentModal() // this will close the modal programmatically
                  },
                  onClose: () => {},
              });
          }
      }}
    >{buttonText}</motion.button>
  )     
}