import React, {useState, useEffect, useRef} from 'react'
import "../css/Account.css"
import { motion, AnimatePresence } from 'framer-motion'
import { domain } from './Domain'
import useFetch from './useFetch'
import useApiRequest from './useApiRequest';
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'

const Account = () => {
    const imgRef = useRef()
    const [isVisible, setIsVisible] = useState(false)
    const [loaded, setLoaded] = useState(false);
    const { postRequest, putRequest, deleteRequest } = useApiRequest();
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/accounts/profile`);
    const [formData, setFormData] = useState(null)

    const handleImageLoad = () => {
        setLoaded(true);
    };

    const handleChange = (event) => {
        const {name, value} = event.target
        // Update the data state based on the input name
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = ({currentDate}) => {
        return new Intl.DateTimeFormat('en-US', options).format(currentDate);
    } 

    // PUT request
    const handlePut = async (e) => {
        e.preventDefault()
        const formDatas = new FormData(e.target)
        await putRequest(`${domain}/accounts/profile`, formDatas);
        setRefresh(!refresh)
    };

    useEffect(()=>{
        setFormData(data)
        console.log(formData)
    },[data])

    return (
        <motion.div className='d-flex'>
            <AnimatePresence>
                <motion.div
                    className='bg-white AccountForm mb-3 mt-3 m-2'>
                        <p className='d-flex justify-content-between p-2 mb-4 align-items-center border-bottom pb-2'><span className='fw-bold'>Edit Account Information</span> </p>
                        <form onSubmit={handlePut}>
                            <div className='d-flex gap-2'>
                                <div>
                                    <label>Firstname</label>
                                    <input type='text' name='first_name' value={formData && formData.first_name} onChange={handleChange} placeholder='Firstname' />
                                </div>
                                <div>
                                    <label>Lastname</label>
                                    <input type='text' name='last_name' value={formData && formData.last_name} onChange={handleChange} placeholder='Lastname' />
                                </div>
                            </div>
                            {data && (data.regCategory === 'Staff' || data.regCategory === 'Apprentice') && <label>Year of graduation</label>}
                            {data && data.regCategory === 'Farmer' && <label>Year spent in Farming</label>}
                            {data && data.regCategory === 'Buyer' && <label>Year spent in the business</label>}
                            {data && data.regCategory === 'Ambassordor' && <label>Year of Study</label>}
                            {data && data.regCategory === 'TrainingCenter' && <label>Years of Operation</label>}
                            <input type='text' name='year' value={formData && formData.year} onChange={handleChange} placeholder='Year' />
                            <label>Email</label>
                            <input type='email' name='email' value={formData && formData.email} onChange={handleChange} placeholder='Email' />
                            <label>Phone Number</label>
                            <input type='tell' name='contact' value={formData && formData.contact} onChange={handleChange} placeholder='Phone number' />
                            {data && data.regCategory != 'TrainingCenter' && 
                                <>
                                    <label>Date of birth</label>
                                    <input type='date' name='dob' value={formData && formData.dob} onChange={handleChange}   />
                                </>
                            }
                            {data && 
                            (data.regCategory === 'Staff' || data.regCategory === 'Apprentice' || data.regCategory === 'Ambassodor' || data.regCategory === "InternationalApprentice") && 
                            <>
                            <label>Curriculum Vitae</label>
                            <input className='border-0' type='file' name='cv' onChange={handleChange} />
                            </>
                            }
                            <label className='d-none'>Profile Photo</label>
                            <input type='file' ref={imgRef} className='d-none'  name='profile'/>
                            <motion.button
                                whileHover={{scale: 1.05}}
                                whileTap={{scale: 0.9}}
                                className='btn btn-success mt-2'
                                >Update Information</motion.button>
                        </form>
                </motion.div> 
            </AnimatePresence>
            <motion.div className='bg-white w-100 mb-3 mt-3 m-2'>
                <p className='d-flex justify-content-between p-2 mb-4 align-items-center border-bottom pb-2'><span className='fw-bold'>User Saved Profile Information</span> <span className='d-flex gap-2'><i class="fa-solid icon fa-arrows-rotate text-success"></i></span></p>
                <div className='gap-4 details pt-0 ps-3 pe-3 pb-2'>
                    <div className='imgDiv d-flex align-items-center'>
                        <div className='w-25'>
                            {!loaded && <Skeleton height={300} />}
                            <motion.img 
                                title='click me to change profile picture'
                                whileHover={{scale:1.01, x : 10}}
                                whileTap={{scale:0.9, x : 0}}
                                onClick={()=>setIsVisible(!isVisible)}
                                src={data ? domain+data.profile : "/images/user.png" } 
                                onLoad={handleImageLoad}
                                style={{ display: loaded ? 'block' : 'none' }} />
                        </div>
                        <AnimatePresence>
                            {isVisible && (
                                <motion.div
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    exit={{ scale: 0 }}
                                    transition={{ type: 'spring', stiffness: 50, damping: 10 }}
                                    className='text-center w-50'>
                                        <motion.button
                                            initial={{x:1000}}
                                            animate={{x:0}}
                                            whileHover={{scale:1.01}}
                                            whileTap={{scale:0.9}}
                                            onClick={()=>imgRef.current.click()}
                                            className='btn btn-sm btn-success'>Change profile picture</motion.button>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        
                    </div>
                    <div className='flex-grow-1 datas align-items-center d-flex flex-wrap'>
                        <div className='w-25'>
                            <p>Name</p>
                            <p>Contact</p>
                            {data && data.regCategory != 'TrainingCenter' && <p>Gender</p>}
                            {data && 
                            (data.regCategory === 'Staff' || data.regCategory === 'Apprentice' || data.regCategory === 'Ambassodor' || data.regCategory === "InternationalApprentice") && <p>CV</p>
                            }
                            <p>Email</p>
                            <p>Joining Date</p>
                            
                        </div>
                        <div className='w-75'>
                            <p>{data ? data.regCategory != 'TrainingCenter' ? data.first_name + " " + data.last_name: data.name: <Skeleton />}</p>
                            <p>{data ? data.contact : <Skeleton />}</p>
                            {data && data.regCategory != 'TrainingCenter' && <p>{data ? data.gender : <Skeleton />}</p>}
                            {data && 
                            (data.regCategory === 'Staff' || data.regCategory === 'Apprentice' || data.regCategory === 'Ambassodor' || data.regCategory === "InternationalApprentice") && <Link className='fs-6' to={domain+data.cv} target='__blank'>view uploaded cv</Link>
                            }
                            <p>{data ? data.email : <Skeleton />}</p>
                            <p>{data ? formattedDate(data.dateJoined) : <Skeleton />}</p>
                        </div>
                    </div>
                    <div className='w-100 datas'>
                        <p>Location Information</p>
                        <div className='d-flex'>
                            <div className='w-25'>
                                <p>District: {data ? data.district : <Skeleton />}</p>
                                <p>SubCounty: {data ? data.subcounty : <Skeleton />}</p>
                            </div>
                            <div className='w-25'>
                                <p>Parish: {data ? data.parish : <Skeleton />}</p>
                                <p>Cell: {data ? data.cell : <Skeleton />}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default Account