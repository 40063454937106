export const ugafInfo = [
    { title: "FORMATION OF UGAFo" },
    { 
        title: "THE FOUR PILLARS OF UGAFO",
        subItems: [
            { title: "Pillar 1. APPRENTICESHIP FOR GRADUATES" },
            { title: "Pillar 2. THE UGAFO COHORTS" },
            { title: "Pillar 3. THE DIGITAL SUBSISTENCE FARMERS MARKETS" },
            { title: "Pillar 4. THE UGAFO CREDIT SCHEME" }
        ]
    },
    { title: "OBJECTIVES OF UGAFo" },
    { title: "MISSION STATEMENT" },
    { title: "VISION" },
    { title: "CORE VALUES" },
    { title: "MEMBERSHIP TO UGAFo" },
    { title: "SERVICES PROVIDED BY UGAFo" },
    { title: "COUSES CONSIDERED" },
    { title: "BENEFITS OF BEING A MEMBER OF UGAFO FOR GRADUATES" },
    { title: "BENEFITS OF BEING A MEMBER OF UGAFo FOR PEASANTS" }
];

export const bachelor_programs = [
    "Bachelor of Arts with Education",
    "Bachelor of Science with Education",
    "Bachelor of Business Administration",
    "Bachelor of Procurement and Logistics Management",
    "Bachelor of Human Resource",
    "Bachelor of Science in Accounting and Finance",
    "Bachelor of Science in Economics and Statistics",
    "Bachelor of Tourism and Hospitality Management",
    "Bachelor of Laws (LLB)",
    "Bachelor of Social Work and Social Administration",
    "Bachelor of Nursing Science",
    "Bachelor of Arts in Mass Communication",
    "Bachelor of Agricultural Science",
    "Bachelor of Science in Food Science and Technology",
    "Bachelor of Science in Computer Science",
    "Bachelor of Science in Information and Technology",
    "Bachelors of Agribusiness",
    "Bachelors of food science and technology", 
    "Bachelors of Mechanical engineering", 
    "Bachelors of Electrical Engineering", 
    "Bachelors of Mechatronics Engineering",
    "music dance and drama"
]



export const diploma_programs = [
    "Diploma in Clinical Medicine",
    "Diploma in Nursing and Midwifery",
    "Diploma in Business Administration",
    "Diploma in Agribusiness",
    "Diploma in Crop Science or General Agriculture",
    "Diploma in Education (both Science and Arts)",
    "Diploma of Mechanical engineering", 
    "Diploma of Electrical Engineering", 
    "Diploma of Mechatronics Engineering",
    "music dance and drama"
]


export const certificate_programs = [
    "Certificate in Nursing",
    "Certificate in Laboratory Technology",
    "Certificate in Crop Science or Animal Husbandry",
    "Certificate in Accounting and Finance",
    "Certificate in Primary Teaching Education",
    "music dance and drama"
]

export const products = [
    "cocoa", "eggs", "chicken", "Cocoa", "Hass avocados", "coffee"
]

export const jobs = [
    "district coordinator [minimum bachelors degree]", "subcounty coordinator [minimum diploma]", "nurse", "clinician", "lab attendant", "receptionist", "accountant"
]

export const countries = [
    "Denmark", "Norway", "Germany", "Finland", "Netherlands", "Norway", "Canada", "USA"
]