import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const Courses = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='courses'>
            <motion.h2 variants = {item}>COUSES CONSIDERED</motion.h2>
            <motion.h3 variants = {item}>LEVEL OF EDUCATION</motion.h3>
            <motion.p variants = {item}>UGAFo will consider largely bachelor’s degree holders and diploma holders. Certificate holders will depend on the courses selected below to be considered at certificate level.</motion.p>
            <motion.h3 variants = {item}>Bachelors</motion.h3>
            <motion.ul variants = {item}>
                <li>Bachelor of Arts with Education</li>
                <li>Bachelor of Science with Education</li>
                <li>Bachelor of Business Administration</li>
                <li>Bachelor of Procurement and Logistics Management</li>
                <li>Bachelor of Human Resource</li>
                <li>Bachelor of Science in Accounting and Finance</li>
                <li>Bachelor of Science in Economics and Statistics</li>
                <li>Bachelor of Tourism and Hospitality Management</li>
                <li>Bachelor of Laws (LLB)</li>
                <li>Bachelor of Social Work and Social Administration</li>
                <li>Bachelor of Nursing Science</li>
                <li>Bachelor of Arts in Mass Communication</li>
                <li>Bachelor of Agricultural Science</li>
                <li>Bachelor of Science in Food Science and Technology</li>
                <li>Bachelor of Science in Computer Science</li>
                <li>Bachelor of Science in Information and Technology</li>
                <li>Bachelors of Agribusiness</li>
            </motion.ul>
            <motion.h3 variants = {item}>Diplomas</motion.h3>
            <motion.ul variants = {item}>
                <li>Diploma in Clinical Medicine</li>
                <li>Diploma in Nursing and Midwifery</li>
                <li>Diploma in Business Administration</li>
                <li>Diploma in Agribusiness</li>
                <li>Diploma in Crop Science or General Agriculture</li>
                <li>Diploma in Education (both Science and Arts)</li>
            </motion.ul>
            <motion.h3 variants = {item}>Certificates</motion.h3>
            <motion.ul variants = {item}>
                <li>Certificate in Nursing</li>
                <li>Certificate in Laboratory Technology</li>
                <li>Certificate in Crop Science or Animal Husbandry</li>
                <li>Certificate in Accounting and Finance</li>
                <li>Certificate in Primary Teaching Education</li>
            </motion.ul>
    </motion.div>
  )
}

export default Courses