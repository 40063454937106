import React, {useState, useEffect, useRef} from 'react'
import "../css/Register.css"
import { bachelor_programs, diploma_programs, certificate_programs, jobs } from './Data'
import Processing from './Processing'
import useApiRequest from './useApiRequest'
import { domain } from './Domain'
import { motion, AnimatePresence } from 'framer-motion'
import Payment from './Payment'

const RegStaff = () => {
    const { postRequest, response } = useApiRequest()
    const [loading, setLoading] = useState(false)
    const initial = {
        first_name: "",
        last_name: "",
        username: "",
        dob: "",
        gender: "",
        institution: "",
        level: "",
        program: "",
        year: "",
        contact: "",
        district: "",
        subcounty: "",
        parish: "",
        cell: "",
        email: "",
        cv: "",
        nin: "",
        profile: "",
        share: 20000,
        paymentMethod: ""
    }
    const [resp, setResponse] = useState(null)
    const [inputData, setInputData] = useState(initial)
    const fileInput = useRef()
    const [mediaStream, setMediaStream] = useState(null);
    const [level, setLevel] = useState(null)
    const form = useRef()

    const [isFilled, setIsFilled] = useState(false);

    useEffect(() => {
        console.log(inputData)
        const checkFormFilled = () => {
            for (const key in inputData) {
                if (inputData[key] === "") {
                    setIsFilled(false);
                    return;
                }
            }
            setIsFilled(true);
        };

        checkFormFilled();
    }, [inputData]);

    useEffect(() => {
        setInputData(prevInitial => ({
            ...prevInitial,
            level: level === null ? "" : level
        }));
    }, [level]);

    // Event handler for input change
    const handleInputChange = (event) => {
        const {name, value} = event.target
        if(name == "cv"){
            setInputData({
                ...inputData,
                [name]: event.target.files[0]
            });
        }else{
            setInputData({
                ...inputData,
                [name]: value
            });
        }
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setInputData({
            ...inputData,
            "profile": file
        });
        // Handle the selected file
        console.log('Selected file:', file);
    };

    const handleSubmit = async() => {
        setLoading(true)
        //event.preventDefault()
        //console.log(inputData)
        const formData = new FormData()
        formData.append("regCategory","Staff")
        for (const key in inputData) {
            console.log(key, inputData[key])
            formData.append(key, inputData[key]);
        }
        
        const response = await postRequest(`${domain}/accounts/signup`, formData)
        console.log(response)

        setLoading(false)

        //resetiing the form after submission
        setInputData(initial)
        form.current.reset()
    }

    const handleClear = () => {
        setInputData(initial)
        form.current.reset()
    }

    useEffect(()=>{
        if(resp !== null && resp.status === "successful"){
            //alert("The Transaction was successfull!")
            console.log(resp)
            handleSubmit()
        }

    },[resp])

    return (
        <div className='register animate__animated animate__fadeIn'>
            <form onSubmit={handleSubmit} ref={form}>
                <div>
                    <div className='divided'>
                        <div className='div d-block d-md-flex gap-4'>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">Firstname</label>
                                <input type='text' placeholder='Enter your firstname' name='first_name' onChange={handleInputChange} value={inputData.first_name} />
                            </div>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">Lastname</label>
                                <input type='text' placeholder='Enter your lastname' name='last_name' onChange={handleInputChange} value={inputData.last_name} />
                            </div>
                        </div>
                        <div className='div'>
                            <label className="form-label">Username</label>
                            <input type='text' placeholder='username' name='username' onChange={handleInputChange} value={inputData.username} />
                        </div>
                    </div>
                    <div className='divided'>
                        <div className='div d-flex gap-4'>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Date of Birth</label>
                                <input type='date' placeholder='Enter your dob' name='dob' onChange={handleInputChange} value={inputData.age} />
                            </div>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Gender</label>
                                <select name='gender' onChange={handleInputChange} value={inputData.gender}>
                                    <option>Select Your Gender</option>
                                    <option value={"male"}>Male</option>
                                    <option value={"female"}>Female</option>
                                </select>
                            </div>
                        </div>
                        <div className='div'>
                            <label className="form-label">Name of institution</label>
                            <input type='text' placeholder='Enter the name of your institution' name='institution'  onChange={handleInputChange} value={inputData.institution}/>
                        </div>
                    </div>
                    <div className='divided'>
                        <div className='div d-flex gap-4'>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Level</label>
                                <select onChange={(e)=>setLevel(e.target.value)}>
                                    <option value={null}>select a level</option>
                                    <option value={"Degree"}>Degree</option>
                                    <option value={"Diploma"}>Diploma</option>
                                    <option value={"Certificate"}>Certificate</option>
                                </select>
                            </div>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Course pursued</label>
                                <select name='program' onChange={handleInputChange} value={inputData.program}>
                                    <option>select a program</option>
                                    {level === "Degree" && 
                                        bachelor_programs.map((item, index) => (
                                            <option value={item} key={index}>{item}</option>
                                        )) 
                                    }
                                    {level === "Diploma" && 
                                        diploma_programs.map((item, index) => (
                                            <option value={item} key={index}>{item}</option>
                                        )) 
                                    }
                                    {level === "Certificate" && 
                                        certificate_programs.map((item, index) => (
                                            <option value={item} key={index}>{item}</option>
                                        )) 
                                    }
                                    
                                </select>
                            </div>
                        </div>
                        <div className='div d-block d-md-flex gap-4'>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">Year of graduation</label>
                                <input type='year' placeholder='Enter your graduation year' name='year' onChange={handleInputChange} value={inputData.year}/>
                            </div>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">NIN</label>
                                <input type='text' placeholder='Enter your NIN' name='nin' onChange={handleInputChange} value={inputData.nin} />
                            </div>
                        </div>  
                    </div>
                    <div className='divided'>
                        <div className='div'>
                            <label className="form-label">phone number</label>
                            <input type='text' placeholder='Phone number' name='contact' onChange={handleInputChange} value={inputData.contact}/>
                        </div>
                        <div className='div'>
                            <label className="form-label">Email</label>
                            <input type='text' placeholder='Enter your email address' name='email' onChange={handleInputChange} value={inputData.email}/>
                        </div>
                    </div>
                    <div className='divided'>
                        <div className='div d-flex gap-4'>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">District of origin</label>
                                <input type='text' placeholder='district of origin' name='district' onChange={handleInputChange} value={inputData.district}/>
                            </div>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">SubCounty</label>
                                <input type='text' placeholder='sub county' name='subcounty' onChange={handleInputChange} value={inputData.subcounty}/>
                            </div>
                        </div>
                        <div className='div d-flex gap-4'>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Parish</label>
                                <input type='text' placeholder='parish' name='parish' onChange={handleInputChange} value={inputData.parish}/>
                            </div>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Cell</label>
                                <input type='text' placeholder='cell' name='cell' onChange={handleInputChange} value={inputData.cell}/>
                            </div>
                        </div>
                    </div>
                    <div className='divided'>
                        <div className='div d-block d-md-flex gap-4'>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">Upload Your CV</label>
                                <input type='file' name='cv' onChange={handleInputChange} />
                            </div>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">Select an option</label>
                                <div className='d-flex gap-2 align-items-center justify-content-between'>
                                    <img style={{width: 50, height: 50, objectFit: "contain"}} src={inputData.profile !== "" ? URL.createObjectURL(inputData.profile) : "/images/user.png"} />
                                    <button type='button' onClick={()=>fileInput.current.click()} className='btn'><i class="fa-solid fa-file"></i> File System</button>
                                </div>
                                <input type='file' onChange={handleFileInputChange} ref={fileInput} hidden />
                            </div>
                        </div>
                        <div className='div d-block d-md-flex gap-4'>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">Buy a share</label>
                                <input type='number' readOnly placeholder='parish' value={inputData.share} name='share'  onChange={handleInputChange} />
                            </div>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">Select a mode of payment</label>
                                <select name='paymentMethod' onChange={handleInputChange} value={inputData.paymentMethod}>
                                    <option value={""}>select mode of payment</option>
                                    <option value={"mtn"}>MTN Mobile Money</option>
                                    <option value={"airtel"}>Airtel Money</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='divided d-none'>
                        <div className='div'>
                            <label className="form-label">Job Applied For</label>
                            <select>
                                <option name="jobs">select a program</option>
                                    {jobs.map((item, index) => (
                                        <option value={item} key={index}>{item}</option>
                                    )) 
                                    }
                            </select>
                        </div>
                    </div>
                    {isFilled ? 
                        <Payment
                            amount={20000}
                            number = {inputData.contact}
                            email = {inputData.email}
                            buttonText={"Make Payment & Register"}
                            setResponse={setResponse}
                        />
                    :
                        <button type='button' className='btn btn-success disabled'>Make Payment & Register</button>
                    }
                    <button type='button' class="btn btn-danger ms-2 mt-2 mb-2" onClick={handleClear}>Clear Form</button>
                </div>
            </form>
            <AnimatePresence>
                {loading && (
                    <div className="processing-container">
                        <motion.div 
                            key={loading} 
                            initial={{ opacity: 0, scale: 0 }} 
                            animate={{ opacity: 1, scale: 1 }} 
                            exit={{ scale: 0, opacity: 0 }} 
                            className='processing'
                        >
                            <Processing />
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default RegStaff