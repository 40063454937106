import React, { useState } from 'react';
import '../css/Home.css'; // Create a CSS file for styling
import { ugafInfo } from './Data';
import { Link } from 'react-router-dom';
import Formation from './Formation';
import Objectives from './Objectives';
import Mission from './Mission';
import Vision from './Vision';
import CoreValues from './CoreValues';
import Membership from './Membership';
import Services from './Services';
import Courses from './Courses';
import BenefitsGraduates from './BenefitsGraduate';
import BenefitsPeasant from './BenefitsPeasant';
import Pillars from './Pillars';
import Pillar1 from './Pillar1';
import Pillar2 from './Pillar2';
import Pillar3 from './Pillar3';
import Pillar4 from './Pillar4';
import Footer from './Footer';
import { motion } from 'framer-motion';
import { International } from './RegInternational';

const Welcome = () => {
    return(
        <div className='welcome d-flex flex-column justify-content-center align-items-center h-inherit text-center p-4'>
            <motion.h1 initial={{y: "-150%"}} animate={{y: 0}} transition={{delay:.2, duration: 1}}>Welcome to <br /><span>United Graduates Apprenticeship Foundation (UGAFo)</span></motion.h1>
            <motion.h2 initial={{scale: 0}} animate={{scale: 1}} transition={{delay:.3, duration: 1}}><q>The journey to equitable community development starts here</q></motion.h2>
            <motion.img initial={{scale: 0}} animate={{scale: 1}} transition={{delay:.1, duration: 1}} src='/images/bk2.avif' />
        </div>
    )
}

const Home = () => {
    const [active, setActive] = useState(null)
    const handleClick = (e) => {
        setActive(e)
    }
    return (
        <>
            <div className="home-container">
                <div className="side-panel">
                    {ugafInfo.map((item, index) => (
                        <Link onClick={()=>handleClick(index)} key={index}>{item.title}</Link>
                    ))}
                </div>
                <div className="scrollable-content">
                    {active === null ? 
                        <Welcome />
                        :
                        <>
                            { active === 0 && <Formation /> }
                            { active === 1 && <Pillars setActive={setActive} /> }
                            { active === 11 && <Pillar1 /> }
                            { active === 12 && <Pillar2 /> }
                            { active === 13 && <Pillar3 /> }
                            { active === 14 && <Pillar4 /> }
                            { active === 15 && <International /> }
                            { active === 2 && <Objectives /> }
                            { active === 3 && <Mission /> }
                            { active === 4 && <Vision /> }
                            { active === 5 && <CoreValues /> }
                            { active === 6 && <Membership /> }
                            { active === 7 && <Services /> }
                            { active === 8 && <Courses /> }
                            { active === 9 && <BenefitsGraduates /> }
                            { active === 10 && <BenefitsPeasant /> }
                        </>
                    }
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Home;
