import React, { useState } from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'


const Pillars = ( {setActive} ) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = (e) => {
        setActive(e)
    }

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };
    //()=> handleClick(11)
    return (
        <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className='pillars'
        >
            <motion.h2 variants = {item}>THE FOUR PILLARS OF UGAFO</motion.h2>
            <div className='d-flex flex-wrap gap-4 justify-content-center align-items-center'>
                <motion.p className='d-flex p-0 m-0' whileHover={{scale: 1.01}} whileTap={{scale: 0.95, background: "grey"}} onClick={toggleModal} variants = {item}><p className='border-0 m-0' onClick={()=> handleClick(11)}>Ugandan Apprenticeship</p><p className='border-0 m-0' onClick={()=> handleClick(15)}>International Apprenticeship</p></motion.p>
                <motion.p whileHover={{scale: 1.01}} whileTap={{scale: 0.95, background: "grey"}} onClick={()=> handleClick(12)} variants = {item}>The UGAFo cohorts.</motion.p>
                <motion.p whileHover={{scale: 1.01}} whileTap={{scale: 0.95, background: "grey"}} onClick={()=> handleClick(13)} variants = {item}>Digital subsistence farmers’ markets.</motion.p>
                <motion.p whileHover={{scale: 1.01}} whileTap={{scale: 0.95, background: "grey"}} onClick={()=> handleClick(14)} variants = {item}>UGAFo credit scheme.</motion.p>
            </div>
        </motion.div>
    )
}

export default Pillars