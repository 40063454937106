import React, { useState, useRef, useEffect } from 'react'
import "../css/Register.css"
import { bachelor_programs, diploma_programs, certificate_programs, countries } from './Data'
import { container, item } from './Formation'
import Processing from './Processing'
import useApiRequest from './useApiRequest'
import useFetch from './useFetch'
import { domain } from './Domain'
import { motion, AnimatePresence } from 'framer-motion'
import Payment from './Payment'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const International = () => {
    return (
        <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className='international'>
            <motion.h2 variants={item}>International apprenticeship for graduates and UGAFo ambassadors. </motion.h2>
            <motion.p variants={item}>We work in partnership with reputable agencies in host countries to place our apprentices mostly in Agriculture, Hospitality, Nursing, bachelors of food science and technology, bachelors of Mechanical engineering, bachelors of Electrical Engineering, bachelors of Mechatronics Engineering, and diploma of Mechanical engineering, diploma of Electrical Engineering, diploma of Mechatronics Engineering.</motion.p  >
            <motion.p variants={item}>we also offer ESL English teaching sector. Our role in most partnerships is to mobilize, screen and guide the right candidates for the respective program. </motion.p>
            <motion.h3 className='mt-3' variants={item}>we connect our international apprentices to the following countries; </motion.h3>
            <motion.ol variants={item}>
                <li>Denmark. Age, 18-28 yrs. both UGAFo ambassadors at diploma or degree level with a minimum of 2 semesters and maximum of 3 and graduates are considered for this program. </li>
                <li>Norway. Age, 20-40yrs. Only Graduates </li>
                <li>Germany. Age, 18-35yrs. Graduates not more than 4 years in field after graduation. </li>
                <li>Finland. Age, 20-40yrs. Only Graduates</li>
                <li>Netherlands. Age, 18-29yrs. Only Degree holders are considered for Netherlands program.</li>
                <li>Norway. Age, 18 – 28 yrs. Only degree holders.</li>
            </motion.ol>
        </motion.div>
    )
}
const RegInternational = () => {
    const [show, setShow] = useState(false);

    const { data, loading: loadingCountries, error, refresh, setRefresh } = useFetch(`${domain}/countries`);
    const { postRequest, response } = useApiRequest()
    const [loading, setLoading] = useState(false)
    const initial = {
        first_name: "",
        last_name: "",
        username: "",
        dob: "",
        gender: "",
        institution: "",
        level: "",
        program: "",
        year: "",
        nin: "",
        contact: "",
        district: "",
        subcounty: "",
        parish: "",
        cell: "",
        email: "",
        cv: "",
        profile: "",
        makePayment: '',
        share: '',
        paymentMethod: "",
        countries: []
    }
    const [inputData, setInputData] = useState(initial)
    const [resp, setResponse] = useState(null)
    const form = useRef()
    const fileInput = useRef()
    const [level, setLevel] = useState(null)

    const [isFilled, setIsFilled] = useState(false);

    const handleClose = () => {

        setShow(false)
    };
    const handleShow = () => setShow(true);

    useEffect(() => {
        console.log(inputData)
        const checkFormFilled = () => {
            for (const key in inputData) {
                if (inputData[key] === "" || inputData[key].length === 0) {
                    setIsFilled(false);
                    return;
                }
            }
            setIsFilled(true);
        };
        checkFormFilled();


    }, [inputData]);

    useEffect(() => {
        if (inputData.makePayment !== 'yes') {
            setInputData({ ...inputData, paymentMethod: "", share: '' })
        }
        if (inputData.paymentMethod !== "" && inputData.makePayment === 'yes') {
            //setInputData({...inputData, share: ""})
            handleShow()
        }

    }, [inputData.makePayment, inputData.paymentMethod])


    useEffect(() => {
        setInputData(prevInitial => ({
            ...prevInitial,
            level: level === null ? "" : level
        }));
    }, [level]);

    // Event handler for input change
    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target
        if (type === "checkbox") {
            // If it's a checkbox, update selectedProducts
            if (checked) {
                setInputData(prevInputData => ({
                    ...prevInputData,
                    countries: [...prevInputData.countries, value]
                }));
            } else {
                setInputData(prevInputData => ({
                    ...prevInputData,
                    countries: prevInputData.countries.filter(item => item !== value)
                }));
            }
        }
        else if (name == "cv") {
            setInputData({
                ...inputData,
                [name]: event.target.files[0]
            });
        }
        else {
            setInputData({
                ...inputData,
                [name]: value
            });
        }
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setInputData({
            ...inputData,
            "profile": file
        });
        // Handle the selected file
        console.log('Selected file:', file);
    };

    const handleSubmit = async () => {
        setLoading(true)
        //event.preventDefault()
        //console.log(inputData)
        const formData = new FormData()
        formData.append("regCategory", "InternationalApprentice")
        for (const key in inputData) {
            console.log(key, inputData[key])
            if (key === 'countries') {
                inputData[key].forEach(item => {
                    formData.append(key, item);
                });
            } else {
                formData.append(key, inputData[key]);
            }

        }

        const response = await postRequest(`${domain}/accounts/signup`, formData)
        console.log(response)

        setLoading(false)

        //resetiing the form after submission
        setInputData(initial)
        form.current.reset()
    }

    const handleClear = () => {
        setInputData(initial)
        form.current.reset()
    }

    useEffect(() => {
        if (resp !== null && resp.status === "successful") {
            //alert("The Transaction was successfull!")
            console.log(resp)
            handleSubmit()
        }

    }, [resp])


    return (
        <div className='register animate__animated animate__fadeIn'>
            <form onSubmit={handleSubmit} ref={form}>
                <div>
                    <div className='divided'>
                        <div className='div d-block d-md-flex gap-4'>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">Firstname</label>
                                <input type='text' placeholder='Enter your firstname' name='first_name' onChange={handleInputChange} value={inputData.first_name} />
                            </div>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">Lastname</label>
                                <input type='text' placeholder='Enter your lastname' name='last_name' onChange={handleInputChange} value={inputData.last_name} />
                            </div>
                        </div>
                        <div className='div'>
                            <label className="form-label">Username</label>
                            <input type='text' placeholder='username' name='username' onChange={handleInputChange} value={inputData.username} />
                        </div>
                    </div>
                    <div className='divided'>
                        <div className='div d-flex gap-4'>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Date of Birth</label>
                                <input type='date' placeholder='Enter your dob' name='dob' onChange={handleInputChange} value={inputData.age} />
                            </div>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Gender</label>
                                <select name='gender' onChange={handleInputChange} value={inputData.gender}>
                                    <option>Select Your Gender</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </div>
                        </div>
                        <div className='div'>
                            <label className="form-label">Institution Name</label>
                            <input type='text' placeholder='Enter the name of your institution' name='institution' onChange={handleInputChange} value={inputData.institution} />
                        </div>
                    </div>
                    <div className='divided'>
                        <div className='div d-flex gap-4'>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Level</label>
                                <select name="level" onChange={(e) => setLevel(e.target.value)}>
                                    <option value={null}>select a level</option>
                                    <option value={"Degree"}>Degree</option>
                                    <option value={"Diploma"}>Diploma</option>
                                    <option value={"Certificate"}>Certificate</option>
                                </select>
                            </div>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Program</label>
                                <select name='program' onChange={handleInputChange} value={inputData.program}>
                                    <option>select a program</option>
                                    {level === "Degree" &&
                                        bachelor_programs.map((item, index) => (
                                            <option value={item} key={index}>{item}</option>
                                        ))
                                    }
                                    {level === "Diploma" &&
                                        diploma_programs.map((item, index) => (
                                            <option value={item} key={index}>{item}</option>
                                        ))
                                    }
                                    {level === "Certificate" &&
                                        certificate_programs.map((item, index) => (
                                            <option value={item} key={index}>{item}</option>
                                        ))
                                    }

                                </select>
                            </div>
                        </div>
                        <div className='div'>
                            <div className='d-block d-md-flex gap-4'>
                                <div className='w-100 w-md-50 flex-grow-1'>
                                    <label className="form-label">Year of graduation</label>
                                    <input type='year' placeholder='Enter your year of graduation' name='year' onChange={handleInputChange} value={inputData.year} />
                                </div>
                                <div className='w-100 w-md-50 flex-grow-1'>
                                    <label className="form-label">NIN</label>
                                    <input type='text' placeholder='Enter your NIN' name='nin' onChange={handleInputChange} value={inputData.nin} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='divided'>
                        <div className='div'>
                            <label className="form-label">phone number</label>
                            <input type='text' placeholder='Phone number' name='contact' onChange={handleInputChange} value={inputData.contact} />
                        </div>
                        <div className='div'>
                            <label className="form-label">Email</label>
                            <input type='text' placeholder='Enter your email address' name='email' onChange={handleInputChange} value={inputData.email} />
                        </div>
                    </div>
                    <div className='divided'>
                        <div className='div d-flex gap-4'>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">District of origin</label>
                                <input type='text' placeholder='district of origin' name='district' onChange={handleInputChange} value={inputData.district} />
                            </div>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">SubCounty</label>
                                <input type='text' placeholder='sub county' name='subcounty' onChange={handleInputChange} value={inputData.subcounty} />
                            </div>
                        </div>
                        <div className='div d-flex gap-4'>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Parish</label>
                                <input type='text' placeholder='parish' name='parish' onChange={handleInputChange} value={inputData.parish} />
                            </div>
                            <div className='w-50 flex-grow'>
                                <label className="form-label">Cell</label>
                                <input type='text' placeholder='cell' name='cell' onChange={handleInputChange} value={inputData.cell} />
                            </div>
                        </div>
                    </div>
                    <div className='divided'>
                        <div className='div flex-wrap d-flex gap-4'>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">Upload Your CV</label>
                                <input type='file' name='cv' onChange={handleInputChange} />
                            </div>
                            <div className='w-100 w-md-50 flex-grow'>
                                <label className="form-label">Upload Profile Picture</label>
                                <div className='d-flex gap-2 align-items-center '>
                                    <img style={{ width: 50, height: 50, objectFit: "contain" }} src={inputData.profile !== "" ? URL.createObjectURL(inputData.profile) : "/images/user.png"} />
                                    <button type='button' onClick={() => fileInput.current.click()} className='btn'><i class="fa-solid fa-file"></i> File System</button>
                                </div>
                                <input type='file' name="profile" onChange={handleFileInputChange} ref={fileInput} hidden />
                            </div>
                            <div className='w-100 mt-0 pt-0'>
                                <label className='w-100'>Select Country of preference</label>
                                {data && data.map((country) => (
                                    <p key={country.id} className='m-0 p-0'><input type='checkbox' onChange={handleInputChange} name='countries' className='w-auto' value={country.id} /> {country.name}</p>
                                ))}
                            </div>
                        </div>
                        <div className='div'>
                            <div className='d-block d-md-flex gap-4'>
                                <div className='w-100 w-md-50 flex-grow'>
                                    <label className="form-label">Make Payment</label>
                                    <select name='makePayment' onChange={handleInputChange} value={inputData.makePayment}>
                                        <option value={""} disabled>make your payment</option>
                                        <option value={"yes"}>Yes</option>
                                        <option value={"no"}>No</option>
                                    </select>
                                    <input className='d-none' type='number' readOnly placeholder='buy a share' onChange={handleInputChange} value={inputData.share} name='share' />
                                </div>
                                <div className='w-100 w-md-50 flex-grow'>
                                    <label className="form-label">Select a mode of payment</label>
                                    <select name='paymentMethod' disabled={inputData.makePayment === "" || inputData.makePayment === "no"} onChange={handleInputChange} value={inputData.paymentMethod}>
                                        <option value={""} disabled>select mode of payment</option>
                                        <option value={"Mobile Money"}>Mobile Money</option>
                                        <option value={"Card Payment"}>Card Payment</option>
                                    </select>
                                </div>
                            </div>
                            {inputData.share !== '' &&
                                <div>
                                    <p className='text-warning fw-bold fs-6'>Amount Specified for Payment</p>
                                    <p className='text-success fw-bold fs-5'>Ugx {inputData.share !== '' && parseInt(inputData.share).toLocaleString()}/=</p>
                                    <button className='btn btn-sm mt-2 btn-danger' type='button' onClick={handleShow}>Change Payment Amount</button>
                                </div>
                            }
                        </div>
                    </div>
                    {isFilled ?
                        <Payment
                            amount={inputData.share}
                            number={inputData.contact}
                            email={inputData.email}
                            buttonText={"Make Payment & Register"}
                            setResponse={setResponse}
                        />
                        :
                        <button type='button' className='btn btn-success disabled'>Make Payment & Register</button>
                    }
                    <button type='button' class="btn btn-danger ms-2 mt-2 mb-2" onClick={handleClear}>Clear Form</button>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton disabled>
                        <Modal.Title>International Apprentice Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='pb-3'>Make payment for the UGAFo international scheme using mobile money or VISA and pick your receipt from any UGAFo office near you.</p>
                        <input type='number' placeholder='select an amount to pay' onChange={(e) => setInputData({ ...inputData, share: e.target.value })} value={inputData.share} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Save & Contiue
                        </Button>
                    </Modal.Footer>
                </Modal>
            </form>
            <AnimatePresence>
                {loading && (
                    <div className="processing-container">
                        <motion.div
                            key={loading}
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ scale: 0, opacity: 0 }}
                            className='processing'
                        >
                            <Processing />
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default RegInternational