import React from 'react'
import{ motion } from "framer-motion"
import { container, item } from './Formation'

const Objectives = () => {
  return (
    <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className='objectives'>
            <motion.h2 variants = {item}>OBJECTIVES OF UGAFo</motion.h2>
            <motion.ul>
                <motion.li variants = {item}>To equip Ugandan graduates with employable skills in their areas of profession.</motion.li>
                <motion.li variants = {item}>To eradicate poverty in rural areas of Uganda using subsistence farmers’ digital markets for equitable and sustainable development. </motion.li>
                <motion.li variants = {item}>To support subsistence farmers in Uganda to use eco-friendly methods of farming for climate smart agriculture. </motion.li>
                <motion.li variants = {item}>To sensitize subsistence farmers in Uganda about the advantages of having small sized families that they can afford to care for using the available family planning methods. </motion.li>
                <motion.li variants = {item}>To improve post-harvest handling methods through value addition, grading, sorting, packaging and quality assurance of products produced by subsistence farmers in Uganda as stipulated by NDPiii using the simple structure solar drying system. </motion.li>
                <motion.li variants = {item}>To help bridge the gap between the inputs manufacturers and the peasants who are the consumers in this case. This will protect farmers from drug counterfeit. </motion.li>
                <motion.li variants = {item}>To integrate local council 1 members into postharvest handling methods of agricultural produce for quality control in commercialized subsistence farming in Uganda. </motion.li>
                <motion.li variants = {item}>To advocate for farmers’ human rights and general welfare such as access to quality education and inclusive medical care. </motion.li>
                <motion.li variants = {item}>To advocate for fair and equal treatment of graduates in recruitment processes in Uganda as well as protecting job seekers from fake job adverts. UGAFo will follow up every advert and every application that involves UGAFo members. </motion.li>
                <motion.li variants = {item}>To fund and conduct research about different challenges faced by the peasants and the graduates and present the findings to the government authorities in the given areas for improved service delivery in the rural areas of Uganda where peasants live. </motion.li>
                <motion.li variants = {item}>To control price fluctuation of agricultural products in Uganda using price caps as well as price index generated by the UGAFo experts which will protect peasants from losses and make farming more profitable than ever before.</motion.li>
                <motion.li variants = {item}>To bridge the gap between the farmers and the buyers both at notional and international level.</motion.li>
                <motion.li variants = {item}>To help farmers and graduates to access legal services at an affordable price as many have been suffering with different forms of violence but unable to access legal services because of high cost charged by law firms which peasants and fresh graduates cannot afford. </motion.li>
                <motion.li variants = {item}>To create a working environment in Uganda where the graduates are employed basing on technical knowhow but not on favor. </motion.li>
                <motion.li variants = {item}>To fund and operate innovation incubation hubs under different UGAFo cohorts.</motion.li>
                <motion.li variants = {item}>To advocate for the image of farm labor as well as attitude towards farming in Uganda. </motion.li>
                <motion.li variants = {item}>To make every district in Uganda a center for processing agricultural products and value addition. </motion.li>
            </motion.ul>
    </motion.div>
  )
}

export default Objectives